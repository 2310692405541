import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'
import { ReviewPeriodFileState } from '../types'

export const selectReviewPeriodFiles = createSelector(
  (state: StoreState) => state.performance.reviewPeriodFiles,
  reviewPeriodFilesState =>
    memoize((reviewPeriodId?: string) => {
      return Object.values(reviewPeriodFilesState[reviewPeriodId || '']?.entities || {}) as ReviewPeriodFileState[]
    })
)
