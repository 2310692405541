export const ADMIN_PATH = '/admin'
export const SELF_SERVICE_PATH = '/my'
export const EMPTY_PATH = '/standalone'
export const HELP_PATH = '/help'
export const TENANT_PATH = '/workspace'

export const EMP_PATH = `${ADMIN_PATH}/people`

export const EMP_ROUTES = {
  tab: `${EMP_PATH}/:tab?`,
  employee: `${EMP_PATH}/employee/:id`,
  directory: `${EMP_PATH}/directory`
}

export const PAY_PATH = `${ADMIN_PATH}/payroll`

export const PAY_ROUTES = {
  tab: `${PAY_PATH}/:tab?`,
  payRun: `${PAY_PATH}/run/:id/:tab?`,
  bankFile: `${PAY_PATH}/run/:id/bankfile`,
  payRecord: `${PAY_PATH}/run/:id/em/:employeeId`,
  cpfSubmissionCallback: `${PAY_PATH}/cpf/callback`
}

export const LVE_PATH = `${ADMIN_PATH}/leave`

export const LVE_ROUTES = {
  tab: `${LVE_PATH}/:tab?`
}

export const CLA_PATH = `${ADMIN_PATH}/claim`

export const CLA_ROUTES = {
  tab: `${CLA_PATH}/:tab?`,
  entitlement: `${CLA_PATH}/entitlement/:periodYear/:employeeId/:claimTypeId`,
  record: `${CLA_PATH}/record/:id`
}

export const ATT_PATH = `${ADMIN_PATH}/attendance`

export const ATT_ROUTES = {
  tab: `${ATT_PATH}/:tab?`,
  period: `${ATT_PATH}/period/:id/:tab?`,
  dailyRecord: `${ATT_PATH}/daily/:clockDate/:employeeId/:shiftId?`
}

export const SCH_PATH = `${ADMIN_PATH}/schedule`

export const SCH_ROUTES = {
  tab: `${SCH_PATH}/:tab?`,
  records: `${SCH_PATH}/records/:start`
}

export const TAX_PATH = `${ADMIN_PATH}/tax`

export const TAX_ROUTES = {
  tab: `${TAX_PATH}/:tab?`,
  submission: `${TAX_PATH}/submission/:id/:tab?`,
  iras: `${TAX_PATH}/iras`,
  irasCallback: `${TAX_PATH}/iras/callback`,
  formsSubmission: `${TAX_PATH}/submission/:id/form/:employeeTaxNo/:tab?`,
  ir21Submission: `${TAX_PATH}/ir21submission/:id/:tab?`,
  ir21FormsSubmission: `${TAX_PATH}/ir21submission/:id/form/:employeeTaxNo/:tab?`,
  ir21: `${TAX_PATH}/ir21`
}

export const PFM_PATH = `${ADMIN_PATH}/performance`

export const PFM_ROUTES = {
  tab: `${PFM_PATH}/:tab?`,
  reviewPeriod: `${PFM_PATH}/period/:id`
}

export const SETTINGS_PATH = `${ADMIN_PATH}/settings`

export const SETTINGS_ROUTES = {
  main: `${SETTINGS_PATH}/main`,
  companies: `${SETTINGS_PATH}/company`,
  company: `${SETTINGS_PATH}/company/:id`,
  offices: `${SETTINGS_PATH}/office`,
  office: `${SETTINGS_PATH}/office/:id`,
  costCentres: `${SETTINGS_PATH}/costcentre`,
  employmentTypes: `${SETTINGS_PATH}/employmenttype`,
  jobs: `${SETTINGS_PATH}/jobtitle`,
  holidays: `${SETTINGS_PATH}/holiday`,
  holiday: `${SETTINGS_PATH}/holiday/:id`,
  departments: `${SETTINGS_PATH}/department`,
  divisions: `${SETTINGS_PATH}/division`,
  sections: `${SETTINGS_PATH}/section`,
  grps: `${SETTINGS_PATH}/grp`,
  categories: `${SETTINGS_PATH}/category`,
  teams: `${SETTINGS_PATH}/team`,
  docTypes: `${SETTINGS_PATH}/doctype`,
  races: `${SETTINGS_PATH}/race`,
  religions: `${SETTINGS_PATH}/religion`,
  reasons: `${SETTINGS_PATH}/reason`,
  banks: `${SETTINGS_PATH}/bank`,
  qualifications: `${SETTINGS_PATH}/qualification`,
  employmentConfigs: `${SETTINGS_PATH}/employmentconfig`,
  salaryGrades: `${SETTINGS_PATH}/salarygrade`,

  // Payroll
  payGroups: `${SETTINGS_PATH}/paygroup`,
  payGroup: `${SETTINGS_PATH}/paygroup/:id/:tab?`,
  payItemsSg: `${SETTINGS_PATH}/payitem-sg`,
  payItemSg: `${SETTINGS_PATH}/payitem-sg/:id`,
  formulas: `${SETTINGS_PATH}/formula`,
  payCfConfigs: `${SETTINGS_PATH}/pay-cf-config`,

  // Leave
  leaveGroups: `${SETTINGS_PATH}/leavegroup`,
  leaveGroup: `${SETTINGS_PATH}/leavegroup/:id`,
  leaveTypes: `${SETTINGS_PATH}/leavetype`,
  leaveType: `${SETTINGS_PATH}/leavetype/:id`,
  leaveApproval: `${SETTINGS_PATH}/leaveapproval`,
  leaveApprovalTab: `${SETTINGS_PATH}/leaveapproval/:id/:tab?`,
  leaveBuddy: `${SETTINGS_PATH}/leavebuddy`,
  calendarConfig: `${SETTINGS_PATH}/calendar`,
  lveRecordCalendarViewer: `${SETTINGS_PATH}/leave-calendar-viewer`,

  // Claim
  claimTypes: `${SETTINGS_PATH}/claimtype`,
  claimType: `${SETTINGS_PATH}/claimtype/:id`,
  cfConfigs: `${SETTINGS_PATH}/cf-config`,
  expenseTypes: `${SETTINGS_PATH}/expensetype/:id`,
  claimApproval: `${SETTINGS_PATH}/claimapproval`,
  claimApprovalTab: `${SETTINGS_PATH}/claimapproval/:id/:tab?`,
  currencies: `${SETTINGS_PATH}/currency`,
  currencyExchanges: `${SETTINGS_PATH}/exchange-rate`,

  // Attendance
  locations: `${SETTINGS_PATH}/location`,
  attendanceGroups: `${SETTINGS_PATH}/attendance-group`,
  attendanceGroup: `${SETTINGS_PATH}/attendance-group/:id/:tab?`,
  breaks: `${SETTINGS_PATH}/break`,
  otConfigs: `${SETTINGS_PATH}/ot-config`,
  teConfigs: `${SETTINGS_PATH}/te-config`,
  shiftCategories: `${SETTINGS_PATH}/shift-category`,
  shiftRoles: `${SETTINGS_PATH}/shift-role`,
  shiftRole: `${SETTINGS_PATH}/shift-role/:id`,
  shifts: `${SETTINGS_PATH}/shift/`,
  shift: `${SETTINGS_PATH}/shift/:id`,
  workCalendars: `${SETTINGS_PATH}/workcalendar`,
  workCalendar: `${SETTINGS_PATH}/workcalendar/:id`,
  dailyPolicies: `${SETTINGS_PATH}/daily-policy`,
  attendanceApproval: `${SETTINGS_PATH}/attendance-approval`,
  attendanceApprovalTab: `${SETTINGS_PATH}/attendance-approval/:id/:tab?`,
  project: `${SETTINGS_PATH}/project`,
  attManager: `${SETTINGS_PATH}/attendance-reporting-manager`,

  // Performance
  questions: `${SETTINGS_PATH}/question`,
  ratings: `${SETTINGS_PATH}/rating`,
  rating: `${SETTINGS_PATH}/rating/:id`,
  reviewTemplates: `${SETTINGS_PATH}/review-template`,
  reviewTemplate: `${SETTINGS_PATH}/review-template/:id`,

  // Iam
  logins: `${SETTINGS_PATH}/user`,
  login: `${SETTINGS_PATH}/user/:id`,
  idpConfigs: `${SETTINGS_PATH}/security`,

  // Others
  emailGroups: `${SETTINGS_PATH}/emailgroup`,
  emailGroup: `${SETTINGS_PATH}/emailgroup/:id`,
  delegations: `${SETTINGS_PATH}/delegation`,
  ssAccessConfigs: `${SETTINGS_PATH}/ssaccessconfig`,
  selfUpdateConfigs: `${SETTINGS_PATH}/selfupdateconfig`,

  // Integration
  googleCalendars: `${SETTINGS_PATH}/google-calendar`,
  googleCalendar: `${SETTINGS_PATH}/google-calendar/:id`,
  googleCalendarCallback: `${SETTINGS_PATH}/google-calendar-callback`,
  quickBooksCallback: `${SETTINGS_PATH}/quick-books/callback`,
  quickBooks: `${SETTINGS_PATH}/quick-books`,

  xeroCallback: `${SETTINGS_PATH}/xero/callback`,
  xeroTest: `${SETTINGS_PATH}/xero/test`,
  xero: `${SETTINGS_PATH}/xero`
}

export const IAM_PATH = `${ADMIN_PATH}/iam`

export const IAM_ROUTES = {
  iam: `${IAM_PATH}/:tab?`
}

export const JOB_PATH = `${ADMIN_PATH}/automation`

export const JOB_ROUTES = {
  jobs: JOB_PATH
}

export const REPORTS_PATH = `${ADMIN_PATH}/report`

export const REPORTS_ROUTES = {
  main: `${REPORTS_PATH}/main`,
  emMovement: `${REPORTS_PATH}/employee-movement`,
  emDetail: `${REPORTS_PATH}/employee-detail`,
  emFlexi: `${REPORTS_PATH}/employee-flexible`,
  payYtd: `${REPORTS_PATH}/payroll-ytd`,
  payMonthly: `${REPORTS_PATH}/payroll-monthly`,
  payRecon: `${REPORTS_PATH}/payroll-reconciliation`,
  payVariance: `${REPORTS_PATH}/payroll-variance`,
  payItemsMonthly: `${REPORTS_PATH}/payroll-items-monthly`,
  bankFile: `${REPORTS_PATH}/bank-file`,
  payment: `${REPORTS_PATH}/payment`,
  lveEntitlement: `${REPORTS_PATH}/leave-entitlement`,
  lveRecord: `${REPORTS_PATH}/leave-record`,
  claimRecord: `${REPORTS_PATH}/claim-record`,
  attendanceRecord: `${REPORTS_PATH}/attendance-record`,
  timeLog: `${REPORTS_PATH}/time-log`,
  oedSubmissions: `${REPORTS_PATH}/oed-submission`,
  oedSubmission: `${REPORTS_PATH}/oed-submission/:id/:tab?`,
  oedSubmissionRecord: `${REPORTS_PATH}/oed-submission/:id/record/:recordId`,
  oedSubmissionCallback: `${REPORTS_PATH}/oed-submission/callback`
}

export const HELP_ROUTES = {
  page: `${HELP_PATH}/:page`
}

export const TENANT_ROUTES = {
  list: `${TENANT_PATH}/list`
}

export const MY_ROUTES = {
  home: `${SELF_SERVICE_PATH}/home`,
  self: `${SELF_SERVICE_PATH}/self/:tab?`,
  selfTabId: `${SELF_SERVICE_PATH}/self/:tab/:id`,
  calendar: `${SELF_SERVICE_PATH}/calendar`,
  org: `${SELF_SERVICE_PATH}/organisation`,
  report: `${SELF_SERVICE_PATH}/report`,
  user: `${SELF_SERVICE_PATH}/user-setting/:tab?`,

  claimTask: `${SELF_SERVICE_PATH}/task/claim/:id`,

  roLeave: `${SELF_SERVICE_PATH}/leave/:tab?`,

  roSchedule: `${SELF_SERVICE_PATH}/schedule/:tab?`
}

export const EMPTY_ROUTES = {
  leaveTask: `${EMPTY_PATH}/task/leave/:token`,
  claimTask: `${EMPTY_PATH}/task/claim/:token`,
  payslip: `${EMPTY_PATH}/payslip/:tenant_code/:token`,
  timeLogTask: `${EMPTY_PATH}/task/time-log/:token`
}

export const SYS_PATH = `${ADMIN_PATH}/system`

export const SYS_ROUTES = {
  main: `${SYS_PATH}/main`,
  auditLogin: `${SYS_PATH}/user-access-log`,
  auditTable: `${SYS_PATH}/data-update-log`
}
