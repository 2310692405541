import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { FetchStrategy } from '~/types/common'
import { ReviewPeriodState } from '../types'
import { fetchReviewPeriod } from '../actions'

export const useReviewPeriod = (
  id: string,
  fetchStrategy: FetchStrategy = 'always'
): [ReviewPeriodState | undefined, boolean] => {
  const reviewPeriod = useSelector((state: StoreState) => state.performance.reviewPeriods?.entities[id])
  const loading = useSelector((state: StoreState) => state.performance.reviewPeriodsLoading)

  useEffect(() => {
    dispatch(fetchReviewPeriod(id, { strategy: fetchStrategy }))
  }, [id, fetchStrategy])

  return [reviewPeriod, loading]
}
