import React, { FC, useCallback, useEffect, useState } from 'react'
import { UploadFile } from '~/core-components'
import { EditableCard, EditableCardState } from '~/components'
import { usePermissionGate } from '~/features/iam'
import { ActionResult, Errors } from '~/types/store'
import { getBaseUrl } from '~/utils'
import { Permission, PermissionAction, ReviewPeriodStatus } from '~/constants'
import { dispatch } from '~/stores/store'
import { saveReviewPeriod } from '../../../actions'
import { useReviewPeriodFiles } from '../../../hooks'
import { ReviewPeriodState, ISaveReviewPeriod, ReviewPeriodFileState } from '../../../types'
import { EMPTY_REVIEW_PERIOD_FORM_DATA, ReviewPeriodInfoForm } from './ReviewPeriodInfoForm'

interface ReviewPeriodInfoCardProps {
  reviewPeriod?: ReviewPeriodState
  onEdit?: () => void
  onSave?: () => void
  onCancel?: () => void
}

interface FormData extends ISaveReviewPeriod {}

const baseUrl = getBaseUrl('/performance')

export const ReviewPeriodInfoCard: FC<ReviewPeriodInfoCardProps> = ({ reviewPeriod, onEdit, onSave, onCancel }) => {
  const [cardState, setCardState] = useState<EditableCardState>()
  const [formData, setFormData] = useState<FormData>(EMPTY_REVIEW_PERIOD_FORM_DATA)
  const [errors, setErrors] = useState<Errors>()
  const canModify = usePermissionGate(Permission.rating, PermissionAction.Modify)
  const readOnly = cardState !== 'editing' && cardState !== 'saving'
  const [files] = useReviewPeriodFiles(reviewPeriod?.id || '')

  const resetFormData = useCallback((reviewPeriod: ReviewPeriodState | undefined, files: ReviewPeriodFileState[]) => {
    if (reviewPeriod) {
      const { id, name, startDate, endDate, reviewTemplateId, description, hasOverallScore } = reviewPeriod

      const attachments: UploadFile[] = files.map(
        att =>
          ({
            uid: att?.fileId || '',
            name: att?.fileName || '',
            size: att?.fileSize || 0,
            type: att?.fileFormat || '',
            status: 'done',
            url: `${baseUrl}/reviewperiod/${id}/file/${att?.id}/downloadfile`
          } as UploadFile)
      )

      setFormData({
        id,
        name,
        startDate,
        endDate,
        reviewTemplateId,
        description,
        hasOverallScore,
        files: attachments
      })
    } else {
      setFormData(EMPTY_REVIEW_PERIOD_FORM_DATA)
    }
  }, [])

  useEffect(() => {
    resetFormData(reviewPeriod, files)
  }, [resetFormData, reviewPeriod, files])

  const handleEdit = useCallback(() => {
    setCardState('editing')
    typeof onEdit === 'function' && onEdit()
  }, [onEdit])

  const handleSave = useCallback(async () => {
    if (reviewPeriod) {
      setCardState('saving')
      setErrors(undefined)

      typeof onSave === 'function' && onSave()

      let result: ActionResult | undefined
      try {
        result = await dispatch(saveReviewPeriod(formData))
      } catch {
        setCardState('editing')
      }

      if (result?.errors) {
        setCardState('editing')
        setErrors(result.errors)
      }

      if (!result?.errors) {
        setCardState(undefined)
      }
    }
  }, [reviewPeriod, formData, onSave])

  const handleCancel = useCallback(() => {
    typeof onCancel === 'function' && onCancel()
    setCardState(undefined)
    setErrors(undefined)
    resetFormData(reviewPeriod, files)
  }, [resetFormData, reviewPeriod, files, onCancel])

  return (
    <EditableCard
      title="General"
      bodyStyle={{ paddingBottom: reviewPeriod ? 6 : 24, paddingTop: 6 }}
      state={!canModify || reviewPeriod?.status !== ReviewPeriodStatus.Draft ? 'readonly' : cardState}
      className="review-period-info-card"
      onEdit={handleEdit}
      onSave={handleSave}
      onCancel={handleCancel}
    >
      <ReviewPeriodInfoForm
        data={formData}
        status={reviewPeriod?.status as ReviewPeriodStatus}
        errors={errors}
        readOnly={readOnly}
        onChange={data => setFormData({ ...formData, ...data })}
      />
    </EditableCard>
  )
}
