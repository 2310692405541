import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { fetchReviewPeriodEmployees } from '../actions'
import { selectReviewPeriodEmployees } from '../selectors'
import { ReviewPeriodEmployeeState } from '../types'

export const useReviewPeriodEmployees = (reviewPeriodId?: string): [ReviewPeriodEmployeeState[], boolean] => {
  const reviewPeriodEmployees = useSelector(selectReviewPeriodEmployees)(reviewPeriodId) as ReviewPeriodEmployeeState[]
  const loading = useSelector(
    (state: StoreState) => state.performance.reviewPeriodEmployeesLoading[reviewPeriodId || '']
  )

  useEffect(() => {
    if (reviewPeriodId) {
      dispatch(fetchReviewPeriodEmployees(reviewPeriodId))
    }
  }, [reviewPeriodId])

  return [reviewPeriodEmployees, loading]
}
