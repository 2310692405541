import React, { FC, useCallback, useMemo, useState } from 'react'
import { LoadingOutlined } from '@ant-design/icons'
import { Permission, PermissionAction, ReviewPeriodStatus } from '~/constants'
import { Button, Card, ColumnsType, Link, Rate, Space, Spin, Table } from '~/core-components'
import { ErrorDisplay, Person } from '~/components'
import { usePermissionGate } from '~/features/iam'
import { EmSelectionDrawer } from '~/features/employee'
import { getBaseUrl } from '~/utils'
import { Pagination } from '~/types/common'
import { dispatch } from '~/stores/store'
import { ActionResult, Errors } from '~/types/store'
import { useReviewPeriodEmployees } from '../../../hooks'
import { addReviewPeriodEmployees, deleteReviewPeriodEmployee, fetchReviewPeriodEmSelections } from '../../../actions'
import { ReviewPeriodEmployeeState, ReviewPeriodState } from '../../../types'
import './ReviewPeriodEmployeesCard.less'

interface ReviewPeriodEmployeesCardProps {
  reviewPeriod?: ReviewPeriodState
}

type ReviewPeriodEmployeeTable = ReviewPeriodEmployeeState

interface DrawerState {
  visible: boolean
}
const DRAWER_STATE: DrawerState = { visible: false }
const baseUrl = getBaseUrl('/filestore')

export const ReviewPeriodEmployeesCard: FC<ReviewPeriodEmployeesCardProps> = ({ reviewPeriod }) => {
  const [reviewEmployees, loading] = useReviewPeriodEmployees(reviewPeriod?.id)
  const canModify = usePermissionGate(Permission.rating, PermissionAction.Modify)
  const [drawerState, setDrawerState] = useState<DrawerState>(DRAWER_STATE)
  const [saving, setSaving] = useState<string | undefined>(undefined)
  const [deleting, setDeleting] = useState<string | undefined>(undefined)
  const reviewPeriodId = reviewPeriod?.id || ''
  const status = reviewPeriod?.status
  const [errors, setErrors] = useState<Errors>()

  const handleOpenDrawer = useCallback(() => {
    setDrawerState({ visible: true })
  }, [])

  const handleCloseDrawer = useCallback(() => {
    setDrawerState(DRAWER_STATE)
  }, [])

  const handleEdit = useCallback((record: ReviewPeriodEmployeeTable) => {}, [])

  const handleDelete = useCallback(
    async (record: ReviewPeriodEmployeeTable) => {
      let result: ActionResult | undefined
      setDeleting(record.id)
      try {
        result = await dispatch(deleteReviewPeriodEmployee(reviewPeriodId, record.employeeId))
      } finally {
        setDeleting(undefined)
      }

      if (result?.errors) {
        setErrors(result.errors)
      }
    },
    [reviewPeriodId]
  )

  const handleSelect = useCallback(
    async (employeeIds: string[]) => {
      let result: ActionResult | undefined
      setSaving('some')
      try {
        result = await dispatch(addReviewPeriodEmployees(reviewPeriodId, { addAll: false, employeeIds }))
      } finally {
        setSaving(undefined)
      }

      if (result?.errors) {
        setErrors(result.errors)
      }
    },
    [reviewPeriodId]
  )

  const handleAddAllEmployees = useCallback(async () => {
    let result: ActionResult | undefined
    setSaving('all')
    try {
      result = await dispatch(addReviewPeriodEmployees(reviewPeriodId, { addAll: true }))
    } finally {
      setSaving(undefined)
    }

    if (result?.errors) {
      setErrors(result.errors)
    }
  }, [reviewPeriodId])

  const handleFetchData = useCallback(
    (viewId: string, pagination: Pagination, search: string) => {
      if (drawerState.visible) {
        dispatch(fetchReviewPeriodEmSelections(reviewPeriod?.id || '', viewId, pagination, search))
      }
    },
    [drawerState.visible, reviewPeriod?.startDate, reviewPeriod?.endDate]
  )

  const columns: ColumnsType<ReviewPeriodEmployeeTable> = useMemo(() => {
    const columns: ColumnsType<ReviewPeriodEmployeeTable> = [
      {
        title: 'Employee',
        key: 'employeeId',
        dataIndex: 'employeeId',
        render: (value: string, record) => (
          <Person
            name={record.employeeName}
            description={record.employeeDescription}
            photo={record.employeePhotoId && `${baseUrl}/file/${record.employeePhotoId}/thumbnailphoto/36`}
          />
        )
      },
      {
        title: 'Status',
        key: 'employeeStatus',
        dataIndex: 'employeeStatus',
        render: (status: string, record) =>
          record.employeeScore ? <Rate value={record.employeeScore} /> : status ?? 'Not started'
      },
      {
        title: 'Reviewer',
        key: 'reviewerId',
        dataIndex: 'reviewerId',
        render: (value: string, record) => (
          <Person
            name={record.reviewerName}
            description={record.reviewerDescription}
            photo={record.reviewerPhotoId && `${baseUrl}/file/${record.reviewerPhotoId}/thumbnailphoto/36`}
          />
        )
      },
      {
        title: 'Status',
        key: 'reviewerStatus',
        dataIndex: 'reviewerStatus',
        render: (status: string, record) =>
          record.employeeScore ? <Rate value={record.employeeScore} /> : status ?? 'Not started'
      }
    ]

    if (status === ReviewPeriodStatus.Draft) {
      columns.push({
        key: 'action',
        align: 'right',
        width: 100,
        render: (value: string, record) => (
          <Space>
            <Link size="small" onClick={() => handleEdit(record)}>
              edit
            </Link>
            {deleting === record.id ? (
              <Spin indicator={<LoadingOutlined spin />} />
            ) : (
              <Link size="small" onClick={() => handleDelete(record)}>
                delete
              </Link>
            )}
          </Space>
        )
      })
    }

    return columns
  }, [handleEdit, handleDelete, deleting, status])

  return (
    <>
      <Card
        title="Assigned employees"
        className="review-period-employees-card"
        extra={
          canModify &&
          status === ReviewPeriodStatus.Draft && (
            <Space>
              <Button type="primary" onClick={handleAddAllEmployees} loading={saving === 'all'}>
                Add all employees
              </Button>
              <Button onClick={handleOpenDrawer} loading={saving === 'some'}>
                Add by employee
              </Button>
            </Space>
          )
        }
        fitParent
        table
      >
        <ErrorDisplay errors={errors} />
        <Table rowKey="id" dataSource={reviewEmployees} columns={columns} loading={loading} fitParent />
      </Card>
      <EmSelectionDrawer
        {...drawerState}
        viewName="review_period_em"
        title="Add employee to the review"
        okText="Add employee"
        onFetchData={handleFetchData}
        onSelect={handleSelect}
        onClose={handleCloseDrawer}
        resetOnClose={false}
      />
    </>
  )
}
