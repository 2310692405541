import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'
import { QuickBooksClaimItemMapState } from '../types'

export const selectQuickBooksClaimItemMaps = createSelector(
  (state: StoreState) => state.payroll.quickBooksClaimItemMaps,
  quickBooksClaimItemMapsState =>
    memoize((realmId: string): QuickBooksClaimItemMapState[] => {
      return Object.values(quickBooksClaimItemMapsState[realmId]?.entities || {}) as QuickBooksClaimItemMapState[]
    })
)
