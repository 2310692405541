import React, { FC, useCallback, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { Button, Card, ColumnsType, PageHeader, Space, Table, Text } from '~/core-components'
import { DocumentTitle, InactiveTag } from '~/components'
import { usePermissionGate } from '~/features/iam/hooks'
import { Permission, PermissionAction } from '~/constants'
import { isInactive } from '~/utils/dateUtil'
import { SETTINGS_ROUTES } from '~/routes/routes'
import { ReviewTemplateState } from '../../types'
import { useReviewTemplates } from '../../hooks'
import { AddReviewTemplateDrawer } from '../ReviewTemplate/components/AddReviewTemplateDrawer'
import './ReviewTemplates.less'

type ReviewTemplateTable = ReviewTemplateState

const routes = [
  {
    path: SETTINGS_ROUTES.main,
    breadcrumbName: 'Settings'
  },
  {
    path: '',
    breadcrumbName: 'Overview'
  }
]

interface DrawerState {
  visible: boolean
}
const DEFAULT_DRAWER_STATE: DrawerState = { visible: false }

interface ReviewTemplatesProps {}

export const ReviewTemplates: FC<ReviewTemplatesProps> = () => {
  const [drawerState, setDrawerState] = useState<DrawerState>(DEFAULT_DRAWER_STATE)
  const canModify = usePermissionGate(Permission.reviewTemplate, PermissionAction.Modify)
  const [reviewTemplates, loading] = useReviewTemplates()

  const handleAddReviewTemplate = useCallback(() => {
    setDrawerState({ visible: true })
  }, [])

  const handleCloseDrawer = useCallback(() => {
    setDrawerState(DEFAULT_DRAWER_STATE)
  }, [])

  const columns: ColumnsType<ReviewTemplateTable> = useMemo(
    () => [
      {
        title: 'Template',
        key: 'name',
        dataIndex: 'name',
        render: (value: string, record) => (
          <Space direction="vertical" size={1}>
            <Space>
              <Link to={SETTINGS_ROUTES.reviewTemplate.replace(':id', record.id)}>{value}</Link>
              {isInactive(record.inactiveDate) && <InactiveTag />}
            </Space>
            <Text size="small" italic>
              {record.description}
            </Text>
          </Space>
        )
      }
    ],
    []
  )

  return (
    <div id="review-templates" className="review-templates">
      <DocumentTitle title="Review Templates" />
      <PageHeader
        title="Review templates"
        containerId="review-templates"
        breadcrumb={{ routes }}
        extra={
          canModify && (
            <Button key="add" onClick={handleAddReviewTemplate}>
              Add review template
            </Button>
          )
        }
      />
      <div className="review-templates__body">
        <Card table>
          <Table rowKey="id" dataSource={reviewTemplates} pagination={false} columns={columns} loading={loading} />
        </Card>
      </div>
      {canModify && <AddReviewTemplateDrawer {...drawerState} onClose={handleCloseDrawer} />}
    </div>
  )
}
