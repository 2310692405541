import React, { FC, useMemo, useState, useCallback } from 'react'
import classNames from 'classnames'
import { LoadingOutlined } from '@ant-design/icons'
import { Dragger, Link, Modal, RcFile, Space, Spin, Upload, UploadFile, UploadProps } from '../../core-components'
import { request, timeout } from '../../utils/request'
import { Col } from '../Col/Col'
import { Row } from '../Row/Row'
import { ImageAuth } from '../ImageAuth/ImageAuth'
import './UploadDraggerAuth.less'

interface UploadDraggerAuthProps extends Omit<UploadProps, 'onChange' | 'action'> {
  onChange?: (files?: UploadFile[]) => void
  loading?: boolean
  withCache?: boolean
}

const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result as string)
    reader.onerror = error => reject(error)
  })

export const UploadDraggerAuth: FC<UploadDraggerAuthProps> = ({
  fileList,
  className,
  onChange,
  loading,
  withCache = true,
  ...props
}: UploadDraggerAuthProps) => {
  const [previewOpen, setPreviewOpen] = useState(false)
  const [previewImage, setPreviewImage] = useState('')
  const [previewTitle, setPreviewTitle] = useState('')
  const [downloading, setDownloading] = useState<string>()

  const handleFilePreview = useCallback(async (file: UploadFile) => {
    if (file.url && file.type?.startsWith('image/')) {
      setPreviewImage(file.url)
      setPreviewOpen(true)
      setPreviewTitle(file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1))
    } else {
      const { result, status } = await request('get', file.url as string, undefined, {
        responseType: 'blob',
        timeout
      })
      if (status) {
        const objectUrl = URL.createObjectURL(result)
        window.open(objectUrl, '_blank')
        URL.revokeObjectURL(objectUrl)
      }
    }
  }, [])

  const handlePreviewClose = () => setPreviewOpen(false)

  const handleFileDownload = useCallback(async (file: UploadFile) => {
    if (file.url) {
      try {
        setDownloading(file.name)
        const { result, status } = await request('get', file.url, undefined, {
          responseType: 'blob',
          timeout
        })
        if (status) {
          const objectUrl = URL.createObjectURL(result)

          const anchor = document.createElement('a')
          anchor.href = objectUrl
          anchor.download = file.fileName || file.name

          document.body.appendChild(anchor)
          anchor.click()
          document.body.removeChild(anchor)

          URL.revokeObjectURL(objectUrl)
        }
      } finally {
        setDownloading(undefined)
      }
    }
  }, [])

  const getUploadProps = useMemo((): UploadProps => {
    const token = localStorage.getItem('access_token')
    const tenant = localStorage.getItem('tenant') || ''

    return {
      headers: {
        Authorization: `Bearer ${token}`,
        'X-Tenant': tenant
      },
      fileList,
      beforeUpload: async file => {
        let newFiles = []

        const attachment: UploadFile = file
        attachment.url = await getBase64(file as RcFile)

        if (fileList && props.multiple) {
          newFiles = [...fileList, attachment]
        } else {
          newFiles = [attachment]
        }

        typeof onChange === 'function' && onChange(newFiles)
        return false
      },
      itemRender: (originNode, file) => {
        const name = file.name ?? file.fileName
        return (
          <Row className="upload-dragger-auth__row">
            <Col flex={1}>
              <Space>
                <i className="fal fa-paperclip" />
                <Link
                  onClick={() => (file.type?.startsWith('image/') ? handleFilePreview(file) : handleFileDownload(file))}
                >
                  {name}
                </Link>
                {downloading === file.name && <Spin size="small" indicator={<LoadingOutlined spin />} />}
              </Space>
            </Col>
            <Col hidden={props.disabled}>
              <Link size="small" onClick={() => props.onRemove!(file)}>
                remove
              </Link>
            </Col>
          </Row>
        )
      },
      ...props
    } as UploadProps
  }, [fileList, onChange, props, handleFilePreview, handleFileDownload, downloading])

  const description = useMemo(() => {
    return `Please use only the following formats: ${
      props.accept?.split(',')?.join(', ') || 'any'
    }, maximum size of each file is 10 MB.`
  }, [props.accept])

  if (loading) return <Spin indicator={<LoadingOutlined spin />} />

  return (
    <>
      {props.disabled ? (
        <Upload className={classNames('upload-dragger-auth', className)} {...getUploadProps} />
      ) : (
        <Dragger className={classNames('upload-dragger-auth', className)} {...getUploadProps}>
          <i className="fal fa-upload" />
          <p className="ant-upload-text">Click or drag a file here to upload</p>
          <p className="ant-upload-hint">{description}</p>
        </Dragger>
      )}
      <Modal width={560} open={previewOpen} title={previewTitle} footer={<div />} onCancel={handlePreviewClose}>
        <ImageAuth width={500} height={500} backgroundSize="contain" src={previewImage} withCache={withCache} />
      </Modal>
    </>
  )
}
