import { replaceReviewPeriodFiles, setReviewPeriodFilesLoading } from '../reducers'
import { apiGetReviewPeriodFiles } from '../api/review-period.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'

export const fetchReviewPeriodFiles = (
  reviewPeriodId: string,
  options: FetchOptions = DEFAULT_FETCH_OPTIONS
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!reviewPeriodId) return

    const loading = getState().performance.reviewPeriodFilesLoading[reviewPeriodId]
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().performance.reviewPeriodFiles[reviewPeriodId]?.ids?.length > 0
      if (hasData) return
    }

    try {
      dispatch(setReviewPeriodFilesLoading({ reviewPeriodId, loading: true }))
      const { result, status } = await apiGetReviewPeriodFiles(reviewPeriodId)
      if (status) {
        dispatch(replaceReviewPeriodFiles({ reviewPeriodId, data: result }))
      }
    } finally {
      dispatch(setReviewPeriodFilesLoading({ reviewPeriodId, loading: false }))
    }
  }
}
