import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { FetchStrategy } from '~/types/common'
import { ReviewPeriodState } from '../types'
import { fetchReviewPeriods } from '../actions'
import { selectReviewPeriods } from '../selectors'

export const useReviewPeriods = (fetchStrategy: FetchStrategy = 'always'): [ReviewPeriodState[], boolean] => {
  const reviewPeriods = useSelector(selectReviewPeriods)
  const loading = useSelector((state: StoreState) => state.performance.reviewPeriodsLoading)

  useEffect(() => {
    dispatch(fetchReviewPeriods({ strategy: fetchStrategy }))
  }, [fetchStrategy])

  return [reviewPeriods, loading]
}
