import React, { FC } from 'react'
import { Redirect, Route, RouteProps, Switch } from 'react-router-dom'
import { PFM_PATH, PFM_ROUTES } from '~/routes/routes'
import { PerformanceOverview } from './PerformanceOverview/PerformanceOverview'
import { ReviewPeriod } from './ReviewPeriod/ReviewPeriod'
import './PerformanceLayout.less'

const routeList: RouteProps[] = [
  {
    path: PFM_ROUTES.tab,
    component: PerformanceOverview,
    exact: true
  },
  {
    path: PFM_ROUTES.reviewPeriod,
    component: ReviewPeriod,
    exact: true
  }
]

export const PerformanceLayout: FC = () => {
  return (
    <div className="performance-layout">
      <Switch>
        <Redirect exact from={PFM_PATH} to={PFM_ROUTES.tab.replace(':tab?', 'period')} />
        {routeList.map(route => (
          <Route key={route.path as string} {...route} />
        ))}
      </Switch>
    </div>
  )
}
