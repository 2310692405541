import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { FetchStrategy } from '~/types/common'
import { StoreState } from '~/types/store'
import { fetchQuickBooksClaimItemMaps } from '../actions'
import { QuickBooksClaimItemMapState } from '../types'
import { selectQuickBooksClaimItemMaps } from '../selectors'

export const useQuickBooksClaimItemMaps = (
  realmId?: string,
  fetchStrategy: FetchStrategy = 'when-empty'
): [QuickBooksClaimItemMapState[], boolean] => {
  const lineItemMap = useSelector(selectQuickBooksClaimItemMaps)(realmId || '')
  const loading = useSelector((state: StoreState) => state.payroll.quickBooksClaimItemMapsLoading[realmId || ''])

  useEffect(() => {
    dispatch(fetchQuickBooksClaimItemMaps(realmId, { strategy: fetchStrategy }))
  }, [realmId, fetchStrategy])

  return [lineItemMap, loading]
}
