import { showSuccess, showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { apiDeleteReviewPeriodEmployee } from '../api/review-period.api'
import { removeReviewPeriodEmployee } from '../reducers'

export const deleteReviewPeriodEmployee = (reviewPeriodId: string, employeeId: string): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!reviewPeriodId || !employeeId) return

    const { status, errors, message, errorData } = await apiDeleteReviewPeriodEmployee(reviewPeriodId, employeeId)
    if (status) {
      const reviewPeriodEmployee = Object.values(
        getState().performance.reviewPeriodEmployees[reviewPeriodId].entities || {}
      ).find(x => x?.employeeId === employeeId)

      if (reviewPeriodEmployee?.id)
        dispatch(removeReviewPeriodEmployee({ reviewPeriodId, id: reviewPeriodEmployee.id }))

      showSuccess('Deleted')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
