import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Dictionary } from '@reduxjs/toolkit'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { FetchStrategy } from '~/types/common'
import { ReviewTemplateState } from '../types'
import { fetchReviewTemplates } from '../actions'

export const useReviewTemplatesDict = (
  fetchStrategy: FetchStrategy = 'always'
): [Dictionary<ReviewTemplateState>, boolean] => {
  const reviewTemplates = useSelector((state: StoreState) => state.performance.reviewTemplates.entities)
  const loading = useSelector((state: StoreState) => state.performance.reviewTemplatesLoading)

  useEffect(() => {
    dispatch(fetchReviewTemplates({ strategy: fetchStrategy }))
  }, [fetchStrategy])

  return [reviewTemplates, loading]
}
