import React, { FC, ChangeEvent, useState, useCallback, useEffect, useMemo } from 'react'
import moment from 'moment-timezone'
import confirm from 'antd/lib/modal/confirm'
import { RangeValue } from 'rc-picker/lib/interface'
import { Alert, Button, Form, Input, Space, Switch, UploadFile } from '~/core-components'
import { Col, InfoTooltip, Row, UploadDraggerAuth } from '~/components'
import { Errors } from '~/types/store'
import { useFocus } from '~/hooks/use-focus'
import { ReviewPeriodFileTypeAllowed, ReviewPeriodStatus } from '~/constants'
import { useReviewTemplatesDict } from '../../../hooks'
import { ISaveReviewPeriod } from '../../../types'
import { SelectReviewTemplate } from '../../ReviewTemplate/components/SelectReviewTemplate'
import './ReviewPeriodInfoForm.less'
import { dispatch } from '~/stores/store'
import { publishReviewPeriod } from '~/features/performance/actions'

interface ReviewPeriodInfoFormProps {
  data: ISaveReviewPeriod
  status?: ReviewPeriodStatus
  readOnly?: boolean
  errors?: Errors
  onChange: (data: ISaveReviewPeriod) => void
}

export const EMPTY_REVIEW_PERIOD_FORM_DATA: ISaveReviewPeriod = {
  name: '',
  startDate: '',
  endDate: '',
  reviewTemplateId: '',
  description: '',
  hasOverallScore: true,
  files: []
}

export const ReviewPeriodInfoForm: FC<ReviewPeriodInfoFormProps> = ({ data, status, readOnly, errors, onChange }) => {
  const [formData, setFormData] = useState<ISaveReviewPeriod>(EMPTY_REVIEW_PERIOD_FORM_DATA)
  const [focusRef] = useFocus(!readOnly)
  const [reviewTemplatesDict] = useReviewTemplatesDict()
  const id = data.id

  useEffect(() => {
    if (data) {
      setFormData(data)
    } else {
      setFormData(EMPTY_REVIEW_PERIOD_FORM_DATA)
    }
  }, [data])

  const handleFormDataChange = useCallback(
    (updates: { [field: string]: any }) => {
      setFormData(formData => {
        const updated = { ...formData, ...updates }
        typeof onChange === 'function' && onChange(updated)
        return updated
      })
    },
    [onChange]
  )

  const handleFileChange = useCallback(
    (files?: UploadFile[]) => {
      if (files && files.length > 0) {
        const updated = { ...data, files: files }
        typeof onChange === 'function' && onChange(updated)
      }
    },
    [data, onChange]
  )

  const handleFileRemove = useCallback(
    (file?: UploadFile) => {
      let deletedFileIds: string[] | undefined = data.deletedFileIds || []
      if (file) {
        const isNewFile = file instanceof File
        if (!isNewFile) deletedFileIds?.push(file.uid)

        const files = data.files || []
        const updated = {
          ...data,
          files: files.filter(x => x.uid !== file.uid),
          ...(isNewFile ? {} : { deletedFileIds: deletedFileIds })
        }
        typeof onChange === 'function' && onChange(updated)
      }
    },
    [data, onChange]
  )

  const handlePublish = useCallback(() => {
    if (id && data) {
      confirm({
        title: 'Publish review period',
        content: `Do you want to publish "${data?.name}"?`,
        onOk: () => {
          dispatch(publishReviewPeriod(id))
        },
        okText: 'Publish'
      })
    }
  }, [id, data])

  const infoDescription = useMemo(() => {
    const startDate = moment(formData.startDate)
    const endDate = moment(formData.endDate)
    const today = moment()

    if (today.isBefore(startDate)) {
      return {
        message: `This performance review period is in draft mode and has not been published yet. The review period is scheduled to start on ${startDate.format(
          'MMMM D, YYYY'
        )} and end on ${endDate.format('MMMM D, YYYY')}. Launch it now to begin the review process.`,
        showPublishButton: true
      }
    }

    if (today.isBetween(startDate, endDate, null, '[]')) {
      return {
        message: `This performance review period was scheduled to start on ${startDate.format(
          'MMMM D, YYYY'
        )}, but it has not been published yet. To ensure timely reviews, launch it as soon as possible.`,
        showPublishButton: true
      }
    }

    if (today.isAfter(endDate)) {
      return {
        message: `This performance review period was scheduled to run from ${startDate.format(
          'MMMM D, YYYY'
        )} to ${endDate.format(
          'MMMM D, YYYY'
        )}, but it was never published. Since the review period has already ended, launching it now may not be meaningful.`,
        showPublishButton: false
      }
    }

    return { message: '', showPublishButton: false }
  }, [formData.startDate, formData.endDate, status])

  return (
    <div className="review-period-info-form">
      <Row>
        <Col span={24}>
          <Form.Item label="Name" validateStatus={errors?.name ? 'error' : ''} help={errors?.name}>
            <Input
              ref={focusRef}
              readOnly={readOnly}
              value={formData.name}
              onChange={(event: ChangeEvent<HTMLInputElement>) => handleFormDataChange({ name: event.target.value })}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={15}>
        <Col>
          <Form.Item label="Date" validateStatus={errors?.startDate ? 'error' : ''} help={errors?.startDate}>
            <Input.DateRange
              inputReadOnly={readOnly}
              value={[
                formData.startDate ? moment(formData.startDate) : null,
                formData.endDate ? moment(formData.endDate) : null
              ]}
              onCalendarChange={(dates: RangeValue<moment.Moment>) => {
                const startDate = dates && dates[0] ? dates[0].format('YYYY-MM-DD') : null
                const endDate = dates && dates[1] ? dates[1].format('YYYY-MM-DD') : null

                if (
                  startDate !== null &&
                  endDate !== null &&
                  (startDate !== data.startDate || endDate !== data.endDate)
                ) {
                  handleFormDataChange({ startDate, endDate })
                }
              }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row hidden={!readOnly || status !== ReviewPeriodStatus.Draft}>
        <Col span={24}>
          <Form.Item label="">
            <Alert
              type="info"
              message={
                <Space direction="vertical" align="center">
                  {infoDescription.message}
                  {infoDescription.showPublishButton && (
                    <Button type="primary" onClick={handlePublish}>{`Publish ${
                      data.name?.toLowerCase() || 'review period'
                    }`}</Button>
                  )}
                </Space>
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form.Item
            label="Review template"
            validateStatus={errors?.reviewTemplateId ? 'error' : ''}
            help={errors?.reviewTemplateId}
          >
            <SelectReviewTemplate
              value={formData.reviewTemplateId}
              readOnly={readOnly}
              onChange={(reviewTemplateId: string) => {
                var reviewTemplate = reviewTemplatesDict[reviewTemplateId]

                handleFormDataChange({
                  reviewTemplateId,
                  hasOverallScore: reviewTemplate?.hasOverallScore,
                  description: reviewTemplate?.description
                })
              }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form.Item
            label="Overall score"
            validateStatus={errors?.hasOverallScore ? 'error' : ''}
            help={errors?.hasOverallScore}
          >
            <Switch
              checked={formData.hasOverallScore}
              disabled={readOnly}
              onChange={(hasOverallScore: boolean) => handleFormDataChange({ hasOverallScore })}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form.Item label="Description" validateStatus={errors?.description ? 'error' : ''} help={errors?.description}>
            <Input.TextArea
              rows={2}
              readOnly={readOnly}
              value={formData.description}
              onChange={(event: ChangeEvent<HTMLTextAreaElement>) =>
                handleFormDataChange({ description: event.target.value })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form.Item label="Files" validateStatus={errors?.files ? 'error' : ''} help={errors?.files}>
            <UploadDraggerAuth
              accept={ReviewPeriodFileTypeAllowed.toString()}
              fileList={data.files}
              multiple
              disabled={readOnly}
              onChange={handleFileChange}
              onRemove={handleFileRemove}
            />
          </Form.Item>
        </Col>
      </Row>
    </div>
  )
}
