import { showError, showSuccess } from '~/utils'
import { ThunkResult } from '~/types/store'
import { apiSaveReviewPeriod } from '../api/review-period.api'
import { ISaveReviewPeriod, ReviewPeriodState } from '../types'
import { setReviewPeriod } from '../reducers'
import { fetchReviewPeriodFiles } from './fetch-review-period-files'
import { fetchReviewPeriodEmployees } from './fetch-review-period-employees'
import pick from 'lodash/pick'

export const saveReviewPeriod = (request: ISaveReviewPeriod): ThunkResult<void> => {
  return async (dispatch, getState) => {
    const { status, result, errors, message, errorData } = await apiSaveReviewPeriod(request)
    if (status) {
      const reviewPeriodId = result?.id || request.id
      if (reviewPeriodId) {
        dispatch(fetchReviewPeriodFiles(reviewPeriodId))
        dispatch(fetchReviewPeriodEmployees(reviewPeriodId))
      }
      const reviewPeriod = getState().performance.reviewPeriods?.entities[reviewPeriodId || '']
      if (reviewPeriod) {
        const updated: ReviewPeriodState = {
          ...(reviewPeriod as ReviewPeriodState),
          ...request
        }

        dispatch(setReviewPeriod(updated))
      } else {
        const newReviewPeriod: ReviewPeriodState = {
          ...(pick(
            request,
            'name',
            'startDate',
            'endDate',
            'reviewTemplateId',
            'description',
            'hasOverallScore'
          ) as ReviewPeriodState),
          ...result
        }

        dispatch(setReviewPeriod(newReviewPeriod))
      }

      showSuccess('Saved')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { result, errors }
  }
}
