import React, { ChangeEvent, FC, useCallback, useEffect, useMemo, useState } from 'react'
import { Alert, Button, Checkbox, CheckboxChangeEvent, Input, Link, Select } from '~/core-components'
import { Col, EditableCard, EditableCardState, InactiveTag, Row } from '~/components'
import { usePermissionGate } from '~/features/iam'
import {
  Permission,
  PermissionAction,
  emptyGuid,
  QuickBooksSplitType,
  ClaPaymentMethod,
  ClaExpenseSubmissionType
} from '~/constants'
import { dispatch } from '~/stores/store'
import { ActionResult, Errors } from '~/types/store'
import { updateQuickBooksClaimItemMaps } from '../../actions'
import { useQuickBooksClaimItemMaps } from '../../hooks'
import { QuickBooksClaimItemMapState } from '../../types'
import { QuickBooksSelectAccount } from './QuickBooksSelectAccount'
import { ExpenseTypeKeyValues, useClaimTypesDict, useExpenseTypesDict } from '~/features/claim'
import { isInactive } from '~/utils'
import './QuickBooksClaimItemMap.less'

interface QuickBooksClaimItemMapProps {
  realmId?: string
}

interface FormData {
  claimItemMaps: QuickBooksClaimItemMapState[]
}

const EMPTY_FORM_DATA: FormData = {
  claimItemMaps: []
}

const NEW_LINE_ITEM_MAP: QuickBooksClaimItemMapState = {
  id: emptyGuid,
  claimTypeId: undefined,
  ctExpenseTypeId: undefined,
  description: undefined,
  debitCode: undefined,
  creditCode: undefined,
  contactId: undefined,
  splitType: undefined
}

export const QuickBooksClaimItemMap: FC<QuickBooksClaimItemMapProps> = ({ realmId }) => {
  const [cardState, setCardState] = useState<EditableCardState>()
  const canModify = usePermissionGate(Permission.quickBooks, PermissionAction.Modify)
  const readOnly = cardState !== 'editing'
  const [errors, setErrors] = useState<Errors>()
  const [claimItemMaps, claimItemMapsLoading] = useQuickBooksClaimItemMaps(realmId)
  const [formData, setFormData] = useState<FormData>(EMPTY_FORM_DATA)
  const [claimTypes] = useClaimTypesDict()
  const [expenseTypes] = useExpenseTypesDict()

  const directClaimTypes = useMemo(
    () =>
      Object.values(claimTypes)
        .filter(
          ct =>
            (!isInactive(ct?.inactiveDate) || claimItemMaps.some(pcm => pcm.claimTypeId === ct?.id)) &&
            ct?.paymentMethod === ClaPaymentMethod.directPayment
        )
        .sort((a, b) => a?.name.localeCompare(b?.name || '') || 0),
    [claimTypes, claimItemMaps]
  )

  useEffect(() => {
    if (claimItemMaps) {
      setFormData({ claimItemMaps })
    } else {
      setFormData(EMPTY_FORM_DATA)
    }
  }, [claimItemMaps])

  const handleFormDataChange = useCallback((updates: { [field: string]: any }) => {
    setErrors(undefined)
    setFormData(data => ({ ...data, ...updates }))
  }, [])

  const handleEdit = useCallback(() => {
    setCardState('editing')
  }, [])

  const handleSave = useCallback(async () => {
    setCardState('saving')
    setErrors(undefined)

    let result: ActionResult | undefined
    try {
      result = await dispatch(updateQuickBooksClaimItemMaps(realmId || '', formData.claimItemMaps))
    } catch {
      setCardState('editing')
    }

    if (result?.errors) {
      setCardState('editing')
      setErrors(result.errors)
    }

    if (!result?.errors) {
      setCardState(undefined)
    }
  }, [realmId, formData])

  const handleCancel = useCallback(() => {
    setCardState(undefined)
    setErrors(undefined)

    if (claimItemMaps) {
      setFormData({ claimItemMaps })
    }
  }, [claimItemMaps])

  return (
    <EditableCard
      className="quick-books-claim-item-map"
      title="Map claim type to QuickBooks Account"
      bodyStyle={{ paddingBottom: !readOnly ? 6 : 24, paddingTop: 6 }}
      state={canModify ? cardState : 'readonly'}
      onEdit={handleEdit}
      onSave={handleSave}
      onCancel={handleCancel}
      loading={claimItemMapsLoading}
    >
      <Row>
        <Col span={24}>
          {errors && Object.keys(errors).length > 0 && (
            <Alert
              className="quick-books-claim-item-map__error"
              type="error"
              message={
                <>
                  {Object.keys(errors).map((error, index) => {
                    let message = `${errors[error]}`
                    const match = error.match(/claimItems\[(\d+)\]/)
                    if (match) {
                      const lineIndex = parseInt(match[1], 10)
                      message = `${errors[error]} at line item ${lineIndex + 1}`
                    }

                    return <div key={index}>{message}</div>
                  })}
                </>
              }
            />
          )}
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Row gutter={16}>
            <Col span={4}>
              <div className="quick-books-claim-item-map__row-title">Claim type</div>
            </Col>
            <Col span={4}>
              <div className="quick-books-claim-item-map__row-title">Expense type</div>
            </Col>
            <Col span={4}>
              <div className="quick-books-claim-item-map__row-title">Description</div>
            </Col>
            <Col span={5}>
              <div className="quick-books-claim-item-map__row-title">Debit</div>
            </Col>
            <Col span={5}>
              <div className="quick-books-claim-item-map__row-title">Credit</div>
            </Col>
            <Col span={1}>Split</Col>
            <Col span={1}></Col>
          </Row>
          {formData.claimItemMaps.map((t, index) => {
            const claimType = claimTypes[t?.claimTypeId || '']
            let descriptionPlaceholder = t.ctExpenseTypeId
              ? `${claimType?.name}/${expenseTypes[t?.ctExpenseTypeId || '']?.name}`
              : claimType?.name

            return (
              <Row
                gutter={16}
                key={index}
                align="middle"
                className="quick-books-claim-item-map__row quick-books-claim-item-map__row-indent"
              >
                <Col span={4}>
                  <Select
                    id="claimType"
                    value={t.claimTypeId}
                    showSearch
                    allowClear={false}
                    readOnly={readOnly}
                    optionFilterProp="title"
                    dropdownMatchSelectWidth={false}
                    onChange={(claimTypeId: string) =>
                      handleFormDataChange({
                        claimItemMaps: [
                          ...formData.claimItemMaps.slice(0, index),
                          { ...formData.claimItemMaps[index], claimTypeId },
                          ...formData.claimItemMaps.slice(index + 1, formData.claimItemMaps.length)
                        ]
                      })
                    }
                  >
                    {directClaimTypes.map(ct => {
                      return (
                        <Select.Option key={ct?.id} value={ct?.id || ''} title={ct?.name}>
                          {isInactive(ct!.inactiveDate) && <InactiveTag />}
                          {ct?.name}
                        </Select.Option>
                      )
                    })}
                  </Select>
                </Col>
                <Col span={4}>
                  {claimType?.expenseSubmissionType === ClaExpenseSubmissionType.Multiple && (
                    <ExpenseTypeKeyValues
                      claimTypeId={t.claimTypeId || ''}
                      value={t.ctExpenseTypeId}
                      placeholder={'All expenses'}
                      readOnly={readOnly}
                      optionFilterProp="title"
                      dropdownMatchSelectWidth={false}
                      onChange={(ctExpenseTypeId: string) =>
                        handleFormDataChange({
                          claimItemMaps: [
                            ...formData.claimItemMaps.slice(0, index),
                            { ...formData.claimItemMaps[index], ctExpenseTypeId },
                            ...formData.claimItemMaps.slice(index + 1, formData.claimItemMaps.length)
                          ]
                        })
                      }
                    />
                  )}
                </Col>
                <Col span={4}>
                  <Input
                    readOnly={readOnly}
                    value={t.description}
                    placeholder={descriptionPlaceholder}
                    onChange={(event: ChangeEvent<HTMLInputElement>) =>
                      handleFormDataChange({
                        claimItemMaps: [
                          ...formData.claimItemMaps.slice(0, index),
                          { ...formData.claimItemMaps[index], description: event.target.value },
                          ...formData.claimItemMaps.slice(index + 1, formData.claimItemMaps.length)
                        ]
                      })
                    }
                  />
                </Col>
                <Col span={5}>
                  <QuickBooksSelectAccount
                    realmId={realmId}
                    value={t.debitCode}
                    readOnly={readOnly}
                    onChange={(debitCode: string) =>
                      handleFormDataChange({
                        claimItemMaps: [
                          ...formData.claimItemMaps.slice(0, index),
                          { ...formData.claimItemMaps[index], debitCode },
                          ...formData.claimItemMaps.slice(index + 1, formData.claimItemMaps.length)
                        ]
                      })
                    }
                  />
                </Col>
                <Col span={5}>
                  <QuickBooksSelectAccount
                    realmId={realmId}
                    value={t.creditCode}
                    readOnly={readOnly}
                    onChange={(creditCode: string) =>
                      handleFormDataChange({
                        claimItemMaps: [
                          ...formData.claimItemMaps.slice(0, index),
                          { ...formData.claimItemMaps[index], creditCode },
                          ...formData.claimItemMaps.slice(index + 1, formData.claimItemMaps.length)
                        ]
                      })
                    }
                  />
                </Col>
                <Col span={1}>
                  <Checkbox
                    readOnly={readOnly}
                    checked={t.splitType === QuickBooksSplitType.Employee}
                    onChange={(event: CheckboxChangeEvent) => {
                      handleFormDataChange({
                        claimItemMaps: [
                          ...formData.claimItemMaps.slice(0, index),
                          {
                            ...formData.claimItemMaps[index],
                            splitType: event.target.checked ? QuickBooksSplitType.Employee : ''
                          },
                          ...formData.claimItemMaps.slice(index + 1, formData.claimItemMaps.length)
                        ]
                      })
                    }}
                  />
                </Col>
                <Col span={1} hidden={readOnly}>
                  <Button
                    type="text"
                    icon={<i className="fal fa-xmark" />}
                    onClick={() =>
                      handleFormDataChange({
                        claimItemMaps: [
                          ...formData.claimItemMaps.slice(0, index),
                          ...formData.claimItemMaps.slice(index + 1, formData.claimItemMaps.length)
                        ]
                      })
                    }
                  />
                </Col>
              </Row>
            )
          })}
          <Row hidden={readOnly}>
            <Col span={6}>
              <Link
                onClick={() => handleFormDataChange({ claimItemMaps: [...formData.claimItemMaps, NEW_LINE_ITEM_MAP] })}
              >
                add more
              </Link>
            </Col>
          </Row>
        </Col>
      </Row>
    </EditableCard>
  )
}
