import React, { forwardRef } from 'react'
import classNames from 'classnames'
import { Select, SelectProps, Space, Text } from '~/core-components'
import { useReviewTemplates } from '../../../hooks'
import './SelectReviewTemplate.less'

export interface SelectReviewTemplateProps extends SelectProps {
  value: string
  onChange: (value: string) => void
}

const SelectReviewTemplateInternal = ({ className, ...props }: SelectReviewTemplateProps, ref: React.Ref<any>) => {
  const classes = classNames('select-review-template', { [`${className}`]: className })
  const [reviewTemplates] = useReviewTemplates()

  return (
    <Select ref={ref} className={classes} allowClear={false} {...props}>
      {reviewTemplates.map(r => (
        <Select.Option key={r.id} value={r.id}>
          <Space direction="vertical" size={1}>
            <span>{r.name}</span>
            <Text type="secondary" size="small">
              {r.description}
            </Text>
          </Space>
        </Select.Option>
      ))}
    </Select>
  )
}

export const SelectReviewTemplate = forwardRef(SelectReviewTemplateInternal)
