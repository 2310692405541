import { showSuccess, showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { apiUnpublishReviewPeriod } from '../api/review-period.api'
import { setReviewPeriod } from '../reducers'
import { ReviewPeriodStatus } from '~/constants'

export const unpublishReviewPeriod = (id: string): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!id) return

    const { status, errors, message, errorData } = await apiUnpublishReviewPeriod(id)
    if (status) {
      const reviewPeriod = getState().performance.reviewPeriods.entities[id]
      if (reviewPeriod) dispatch(setReviewPeriod({ ...reviewPeriod, status: ReviewPeriodStatus.Draft }))

      showSuccess('Reverted to draft')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
