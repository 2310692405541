import { setReviewPeriod, setReviewPeriodsLoading } from '../reducers'
import { apiGetReviewPeriod } from '../api/review-period.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'

export const fetchReviewPeriod = (id: string, options: FetchOptions = DEFAULT_FETCH_OPTIONS): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!id) return

    const loading = getState().performance.reviewPeriodsLoading
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().performance.reviewPeriods?.ids?.length > 0
      if (hasData) return
    }

    try {
      dispatch(setReviewPeriodsLoading(true))
      const { result, status } = await apiGetReviewPeriod(id)
      if (status) {
        dispatch(setReviewPeriod(result))
      }
    } finally {
      dispatch(setReviewPeriodsLoading(false))
    }
  }
}
