import React, { FC, useCallback, useState } from 'react'
import { Button, Card, Link, Space, Tag, Text } from '~/core-components'
import { Col, Row } from '~/components'
import { useSysOptions } from '~/features/employee'
import { usePermissionGate } from '~/features/iam'
import { Permission, PermissionAction } from '~/constants'
import { useReviewQuestionTemplates, useQuestionsDict } from '../../../hooks'
import { ReviewQuestionTemplateState, ReviewTemplateState } from '../../../types'
import { MutateReviewQuestionTemplateDrawer } from './MutateReviewQuestionTemplateDrawer'
import './ReviewQuestionTemplateCard.less'

interface ReviewQuestionTemplateCardProps {
  template?: ReviewTemplateState
}

interface DrawerState {
  visible: boolean
  reviewTemplateId: string
  data?: ReviewQuestionTemplateState
}

const DEFAULT_DRAWER_STATE: DrawerState = { visible: false, reviewTemplateId: '' }

export const ReviewQuestionTemplateCard: FC<ReviewQuestionTemplateCardProps> = ({ template }) => {
  const [drawerState, setDrawerState] = useState<DrawerState>(DEFAULT_DRAWER_STATE)
  const canModify = usePermissionGate(Permission.rating, PermissionAction.Modify)
  const [reviewQuestions] = useReviewQuestionTemplates(template?.id || '')
  const [questionsDict] = useQuestionsDict()
  const [answerTypesDict] = useSysOptions('pfm_answer_type')

  const handleAddQuestion = useCallback(() => {
    setDrawerState({ visible: true, reviewTemplateId: template?.id || '', data: undefined })
  }, [template])

  const handleEditQuestion = useCallback(
    (reviewQuestionTemplate: ReviewQuestionTemplateState) => {
      setDrawerState({ visible: true, reviewTemplateId: template?.id || '', data: reviewQuestionTemplate })
    },
    [template]
  )

  const handleCloseDrawer = useCallback(() => {
    setDrawerState(DEFAULT_DRAWER_STATE)
  }, [])

  return (
    <Card title="Questions" className="review-question-template-card">
      {reviewQuestions.map(
        q =>
          q.questionIds.length > 0 && (
            <Row className="review-question-template-card__questions" align="top">
              <Col flex={1}>
                {q.questionIds.map((id, index) => (
                  <Row key={id} className="review-question-template-card__question">
                    {index !== 0 && (
                      <Col span={24}>
                        <div className="review-question-template-card__question--separator" />
                      </Col>
                    )}
                    <Col span={24}>
                      <Space direction="vertical" size={1}>
                        <b>{questionsDict[id]?.name}</b>
                        {questionsDict[id]?.description && (
                          <Text size="small" italic>
                            {questionsDict[id]?.description}
                          </Text>
                        )}
                      </Space>
                    </Col>
                  </Row>
                ))}
                <div className="review-question-template-card__answer-type">
                  <Space direction="vertical" size={1}>
                    <b>{answerTypesDict[q.answerType]?.value || 'None'}</b>
                    <Space>
                      {q.scales.map(s => (
                        <Tag>{s.name}</Tag>
                      ))}
                    </Space>
                  </Space>
                </div>
              </Col>
              <Col>
                <Link onClick={() => handleEditQuestion(q)}>edit</Link>
              </Col>
            </Row>
          )
      )}
      {canModify && (
        <>
          <Button type="dashed" block onClick={handleAddQuestion}>
            Add question
          </Button>
          <MutateReviewQuestionTemplateDrawer {...drawerState} onClose={handleCloseDrawer} />
        </>
      )}
    </Card>
  )
}
