import { EntityState } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage'
import { QuestionState } from './question.state'
import { RatingScaleState, RatingState } from './rating.state'
import {
  ReviewQuestionRatingScaleTemplateState,
  ReviewQuestionTemplateState,
  ReviewRatingScaleTemplateState,
  ReviewTemplateState
} from './review-template.state'
import { ReviewPeriodEmployeeState, ReviewPeriodFileState, ReviewPeriodState } from './review-period.state'

export const performancePersistConfig = {
  key: 'performance',
  whitelist: [
    'questions',
    'ratings',
    'ratingScales',
    'reviewTemplates',
    'reviewRatingScaleTemplates',
    'reviewQuestionTemplates',
    'reviewQuestionRatingScaleTemplates',
    'reviewPeriods',
    'reviewPeriodFiles',
    'reviewPeriodEmployees'
  ],
  storage
}

export interface PerformanceRootState {
  questions: EntityState<QuestionState>
  questionsLoading: boolean

  ratings: EntityState<RatingState>
  ratingsLoading: boolean
  ratingScales: { [ratingId: string]: EntityState<RatingScaleState> }
  ratingScalesLoading: { [ratingId: string]: boolean }

  reviewTemplates: EntityState<ReviewTemplateState>
  reviewTemplatesLoading: boolean
  reviewRatingScaleTemplates: { [templateId: string]: EntityState<ReviewRatingScaleTemplateState> }
  reviewRatingScaleTemplatesLoading: { [templateId: string]: boolean }
  reviewQuestionTemplates: { [templateId: string]: EntityState<ReviewQuestionTemplateState> }
  reviewQuestionTemplatesLoading: { [templateId: string]: boolean }
  reviewQuestionRatingScaleTemplates: { [questionId: string]: EntityState<ReviewQuestionRatingScaleTemplateState> }
  reviewQuestionRatingScaleTemplatesLoading: { [questionId: string]: boolean }

  reviewPeriods: EntityState<ReviewPeriodState>
  reviewPeriodsLoading: boolean
  reviewPeriodsRefetch: number
  reviewPeriodFiles: { [reviewPeriodId: string]: EntityState<ReviewPeriodFileState> }
  reviewPeriodFilesLoading: { [reviewPeriodId: string]: boolean }
  reviewPeriodEmployees: { [reviewPeriodId: string]: EntityState<ReviewPeriodEmployeeState> }
  reviewPeriodEmployeesLoading: { [reviewPeriodId: string]: boolean }
}
