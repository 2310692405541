export class Permission {
  static get company() {
    return '927b4465-d8cc-494e-9a2e-8ccb8bb45826'
  }
  static get employee() {
    return '24d2b275-d4f2-4efc-9df3-8ff33e1d6dcb'
  }
  static get employeeBasic() {
    return '96786525-b26c-47c2-828b-2fe6149e63b4'
  }
  static get employeePersonal() {
    return '21286a8f-4cb6-40d1-8a6c-c0d6311610d6'
  }
  static get employeeIdentity() {
    return 'ecafb598-df11-4281-9ad9-dad448cc6d58'
  }
  static get employeeAddress() {
    return '119e9b6a-34e1-4c7b-9aff-3e99bf8ab070'
  }
  static get employeeHire() {
    return '86547123-9f31-45f1-adfc-8bde65be4b8a'
  }
  static get employeeCareer() {
    return 'bd468f0f-1ecf-4420-a538-28d596b9961e'
  }
  static get employeeProbation() {
    return 'a691599e-60c6-42ba-acab-21a8f795369f'
  }
  static get employeeRetirement() {
    return 'b636ff02-1eb7-4b18-a0ea-a3297d3026c1'
  }
  static get employeeCalendar() {
    return '3a0041bd-a34f-4bd9-aebc-8a19330ac966'
  }
  static get employeeSalary() {
    return '455d231a-82d5-40b5-b92e-8c250dae21ff'
  }
  static get employeePayGroup() {
    return '0d865bce-736c-438e-b655-f3a07c9a1a11'
  }
  static get employeePayRecurring() {
    return '846d459b-2b50-4468-a800-9db47d8bba4d'
  }
  static get employeePayment() {
    return '46bd7cbd-0918-4375-abbf-e22043e56aa5'
  }
  static get employeeStatutory() {
    return '1d85646c-34be-4b8e-8e8d-2b922f4e863d'
  }
  static get employeeStatExempt() {
    return 'e9d43dcf-529a-46de-bf96-0b7909e5fe16'
  }
  static get employeeLeaveGroup() {
    return '29274080-3428-41d5-b751-8186f9e458c4'
  }
  static get employeeAttendance() {
    return '10cffd91-f0a1-45fc-a27c-1ae58261a23a'
  }
  static get employeeFamily() {
    return '6b337c92-8bb4-4b43-acb1-ca7e42916cd3'
  }
  static get employeeEmergency() {
    return 'b1cf9967-4130-4578-850b-ffd6e614ac3e'
  }
  static get employeeEducation() {
    return 'c079c0bd-b923-4590-9672-3081cb1437e8'
  }
  static get employeeCert() {
    return 'df26d685-ec7b-496e-9a87-90e2256fe103'
  }
  static get employeeDocument() {
    return 'c2566f3e-b409-45f0-9631-1a9a96f94cae'
  }

  static get employeeBasicTab() {
    return [Permission.employeeBasic]
  }
  static get employeePersonalTab() {
    return [Permission.employeePersonal, Permission.employeeIdentity, Permission.employeeAddress]
  }
  static get employeeEmploymentTab() {
    return [
      Permission.employeeHire,
      Permission.employeeCareer,
      Permission.employeeProbation,
      Permission.employeeRetirement
    ]
  }
  static get employeeCalendarTab() {
    return [Permission.employeeCalendar]
  }
  static get employeeSalaryTab() {
    return [Permission.employeeSalary, Permission.employeePayGroup, Permission.employeePayRecurring]
  }
  static get employeePaymentTab() {
    return [Permission.employeePayment]
  }
  static get employeeStatutoryTab() {
    return [Permission.employeeStatutory, Permission.employeeStatExempt]
  }
  static get employeeLeaveTab() {
    return [Permission.employeeLeaveGroup]
  }
  static get employeeAttendanceTab() {
    return [Permission.employeeAttendance]
  }
  static get employeeFamilyTab() {
    return [Permission.employeeFamily]
  }
  static get employeeEmergencyTab() {
    return [Permission.employeeEmergency]
  }
  static get employeeEducationTab() {
    return [Permission.employeeEducation]
  }
  static get employeeCertTab() {
    return [Permission.employeeCert]
  }
  static get employeeDocumentTab() {
    return [Permission.employeeDocument]
  }
  static get allEmployeeTabs() {
    return [
      ...this.employeeBasicTab,
      ...this.employeePersonalTab,
      ...this.employeeEmploymentTab,
      ...this.employeeCalendarTab,
      ...this.employeeSalaryTab,
      ...this.employeePaymentTab,
      ...this.employeeStatutoryTab,
      ...this.employeeLeaveTab,
      ...this.employeeAttendanceTab,
      ...this.employeeFamilyTab,
      ...this.employeeEmergencyTab,
      ...this.employeeEducationTab,
      ...this.employeeCertTab,
      ...this.employeeDocumentTab
    ]
  }

  static get payRun() {
    return '4632981f-0414-4aee-8352-3159edc45210'
  }
  static get cpfSubmission() {
    return '9e53b33b-bae6-47c5-a528-4b2e9701ee96'
  }
  static get ytd() {
    return '591bc83e-b534-4dea-ae68-a2ef6096d879'
  }

  // leave
  static get lveEntitlement() {
    return '87f59df8-9c4e-4d42-b921-bb8d1a7ecaeb'
  }
  static get lveRecord() {
    return 'e36f09a8-ddb4-4875-a3c5-ab0e5b782d14'
  }
  static get allLeaveTransactions() {
    return [this.lveEntitlement, this.lveRecord]
  }

  // claim
  static get claEntitlement() {
    return '17b83cb4-e430-454c-b343-42909aeeb773'
  }
  static get claRecord() {
    return 'f4525bb6-78da-4089-acf5-6a348708d8b4'
  }
  static get allClaimTransactions() {
    return [this.claEntitlement, this.claRecord]
  }

  // attendance
  static get attDailyRecord() {
    return 'aff5de52-dd7f-4887-92cc-74b8e1d3e6c7'
  }
  static get attTimeLog() {
    return 'c103d97d-dccc-46d1-abb7-fd0d1e3c55d6'
  }
  static get allAttendanceTransactions() {
    return [this.attDailyRecord, this.attTimeLog]
  }

  // schedule
  static get schRecord() {
    return '9de2f054-c894-4922-be3d-2d1921a488c9'
  }
  static get allScheduleTransactions() {
    return [this.schRecord]
  }

  // performance
  static get question() {
    return 'f6efc9e9-4831-4974-9c4d-a5ff685277b2'
  }
  static get rating() {
    return 'dbe45570-2968-463d-9517-f057220e582f'
  }
  static get reviewTemplate() {
    return '6ecfd5b3-f007-4718-9656-a991e172cb85'
  }
  static get reviewPeriod() {
    return 'c330ba00-d64a-48a9-abc0-536bdcf156e1'
  }
  static get allPerformanceTransactions() {
    return [this.reviewPeriod]
  }

  // settings
  static get master() {
    return '402f5e6e-aa56-4633-90ab-c2d5ad04b56e'
  }
  static get payMaster() {
    return '784ea773-649b-4865-bdf7-8fda58c69c6e'
  }
  static get payCfConfig() {
    return 'fe1f0e22-e271-4b21-be2c-2b49fa0a32fe'
  }
  static get lveGroup() {
    return '64b3a3ff-7fd0-4fd6-96f2-bb4e2d59258b'
  }
  static get lveType() {
    return '3a295692-0288-49ba-a7e4-cfb9abc96863'
  }
  static get lveApproval() {
    return '2d757734-d5ae-422c-9bcd-e41eb01f9b93'
  }
  static get lveBuddy() {
    return 'c7a9318c-316f-4f99-ab37-bd834641ac5a'
  }
  static get claType() {
    return 'f8889709-cc1d-40f1-97ad-21ac09baad4f'
  }
  static get cfConfig() {
    return '0dae6c70-d1d5-452a-9cc7-2322b49ed761'
  }
  static get claApproval() {
    return '8568e03c-17b8-4f62-8ca9-c9eb22b5b8d5'
  }
  static get claCurrency() {
    return '3385946b-6156-49c9-8cd2-fc068efa58d2'
  }
  static get location() {
    return 'e1a61656-8f6d-420c-ae63-a3457d9f1e67'
  }
  static get attendanceGroup() {
    return 'ce69c2d9-eb07-4933-8cb0-832984e6e8dc'
  }
  static get break() {
    return 'b028b39b-0461-4dc0-9a35-30d960587065'
  }
  static get otConfig() {
    return '80f99fd5-01c0-4641-8766-1637859b111c'
  }
  static get teConfig() {
    return '158c0b68-1dc4-40bd-b5b7-b1c228305527'
  }
  static get shiftCategory() {
    return '68837212-0e37-4a30-baf2-12c33e52f70d'
  }
  static get shiftRole() {
    return 'b9a30bc8-82be-4567-bed1-8d2e28ff719c'
  }
  static get shift() {
    return 'c191f4d0-5504-4431-9df8-7e7c709bbe17'
  }
  static get calendar() {
    // Temporary share with master
    return '402f5e6e-aa56-4633-90ab-c2d5ad04b56e'
    // return 'ca1367ec-7c17-45c2-af82-d9e611b37eb6'
  }
  static get dailyPolicies() {
    return 'feae2935-57f0-4095-9146-b774e82834b9'
  }
  static get project() {
    return 'd7334868-99f9-47fe-adcd-769b64b726c3'
  }
  static get attApproval() {
    return 'eba65c82-32fb-4cdc-91ed-b85b677c8845'
  }
  static get login() {
    return 'ca2d7d72-732d-458f-af06-1bfc6514a3e2'
  }
  static get empConfig() {
    return 'e7cf3664-2368-4b13-8e18-616ef2f903df'
  }
  static get employmentConfig() {
    return '582e65ca-4ebb-4e40-a8bc-aeff6829a8b3'
  }
  static get idpConfig() {
    return '1505e338-3f2a-4f5e-b12e-b595e11e1bb1'
  }
  static get emailGroup() {
    return 'a6da38b0-9248-46d2-80c0-e160963e8bdf'
  }
  static get delegation() {
    return 'e3d7a564-286c-433c-ac29-30381791a0ef'
  }
  static get googleCalendar() {
    return '57fccabf-1b2a-49d5-8b4b-acf8d917555f'
  }
  static get quickBooks() {
    return '7aad87fc-edf8-4aee-a2a0-a20f5fb83e80'
  }
  static get xero() {
    return 'a2f67baa-fd6b-414f-9a80-9d7a8815bc37'
  }
  static get selfUpdateConfig() {
    return '3bf0a9c4-16d5-4219-912a-8be906290b55'
  }
  static get ssAccessConfig() {
    return '3447eac7-8447-4594-8734-92616ee951be'
  }
  static get calendarConfig() {
    return '478156f2-62f4-4abd-8bc6-5ccb8f5443c0'
  }
  static get lveRecordCalendarViewer() {
    return '54d45d50-cc31-489c-9fe5-4795fc4fafce'
  }
  static get attManager() {
    return '6b06cda4-5b9b-4209-b26d-830a1f054d57'
  }
  static get allSettings() {
    return [
      this.master,
      this.payMaster,
      this.payCfConfig,
      this.lveType,
      this.claType,
      this.claCurrency,
      this.login,
      this.empConfig,
      this.employmentConfig,
      this.idpConfig,
      this.emailGroup,
      this.googleCalendar,
      this.xero,
      this.selfUpdateConfig,
      this.ssAccessConfig,
      this.calendarConfig,
      this.lveRecordCalendarViewer,
      this.delegation,
      this.attManager
    ]
  }

  // report
  static get rptEmMovement() {
    return '8eb82608-15d7-4e62-b983-fb665b3ab7d4'
  }
  static get rptEmDetail() {
    return '274febbe-1f29-454d-82f9-bcf75b01edfc'
  }
  static get rptEmFlexi() {
    return '514fa5af-27da-44e8-836f-1bbab1ae78d2'
  }
  static get rptMomOed() {
    return 'f2004cd1-4768-4d6f-a206-7837cf1e02f5'
  }
  static get rptPayMonthly() {
    return 'c084a400-4543-4bfa-8178-be1e70d7031d'
  }
  static get rptPayRecon() {
    return '079063f6-e952-477c-a362-796cd36627de'
  }
  static get rptPayVariance() {
    return '5f4826c9-12fe-4734-82a4-40aa1f1d9743'
  }
  static get rptBankFile() {
    return 'ef1f7df7-99c9-4d33-923d-47de9efb283d'
  }
  static get rptPayment() {
    return 'c5653c8f-9f55-4356-b074-664d6e702808'
  }
  static get rptPayYtd() {
    return 'd59a74a0-3fb7-403c-b19f-b2cea711a197'
  }
  static get rptLveEntitlement() {
    return '414ca6c0-7b00-441c-b121-3942e5d2cf84'
  }
  static get rptLveRecord() {
    return '07d53561-2985-4b4a-9b9f-e914012c706d'
  }
  static get rptClaimRecord() {
    return '152fe36e-f9f1-4ad1-961e-4e3929679884'
  }
  static get rptAttendanceRecord() {
    return '1882008a-08fa-4031-b394-ab344884e40b'
  }
  static get rptTimeLog() {
    return '1ae3768f-070b-4386-bdc5-9380b9a2ea55'
  }
  static get allReports() {
    return [
      this.rptEmMovement,
      this.rptEmDetail,
      this.rptEmFlexi,
      this.rptMomOed,
      this.rptPayMonthly,
      this.rptPayRecon,
      this.rptBankFile,
      this.rptPayment,
      this.cpfSubmission,
      this.rptPayYtd,
      this.rptLveEntitlement,
      this.rptLveRecord,
      this.rptClaimRecord,
      this.rptAttendanceRecord,
      this.rptTimeLog
    ]
  }

  // system
  static get auditLogin() {
    return '264b7c0a-8686-43f7-b7e0-aa1cdb04697b'
  }
  static get auditTable() {
    return 'fcaa28d5-4304-4d45-851a-ca404e3669fb'
  }
  static get allSystems() {
    return [this.auditLogin, this.auditTable]
  }
}

export enum PermissionAction {
  View = 'v',
  Add = 'a',
  Modify = 'm',
  Delete = 'd',
  Email = 'e',
  Publish = 'p',
  Send = 's',
  ActivateAccount = 'c',
  ResetPassword = 'r'
}
