import React, { FC, useCallback, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { Button, Card, ColumnsType, Space, Table, Text } from '~/core-components'
import { PFM_ROUTES } from '~/routes/routes'
import { usePermissionGate } from '~/features/iam'
import { Permission, PermissionAction } from '~/constants'
import { ReviewPeriodState } from '../../types'
import { useReviewPeriods } from '../../hooks'
import { AddReviewPeriodDrawer } from '../ReviewPeriod/components/AddReviewPeriodDrawer'
import './ReviewPeriods.less'
import { useSysOptions } from '~/features/employee'

type ReviewPeriodTable = ReviewPeriodState

interface DrawerState {
  visible: boolean
  data?: ReviewPeriodState
}
const DEFAULT_DRAWER_STATE: DrawerState = { visible: false }

interface ReviewPeriodsProps {}

export const ReviewPeriods: FC<ReviewPeriodsProps> = () => {
  const [drawerState, setDrawerState] = useState<DrawerState>(DEFAULT_DRAWER_STATE)
  const canModify = usePermissionGate(Permission.reviewPeriod, PermissionAction.Modify)
  const [reviewPeriods, loading] = useReviewPeriods()
  const [status] = useSysOptions('pfm_review_period_status')

  const handleAddReviewPeriod = useCallback(() => {
    setDrawerState({ visible: true })
  }, [])

  const handleCloseDrawer = useCallback(() => {
    setDrawerState(DEFAULT_DRAWER_STATE)
  }, [])

  const columns: ColumnsType<ReviewPeriodTable> = useMemo(
    () => [
      {
        title: 'Period',
        key: 'name',
        dataIndex: 'name',
        render: (value: string, record) => (
          <Space direction="vertical" size={1}>
            <Space>
              <Link to={PFM_ROUTES.reviewPeriod.replace(':id', record.id)}>{value}</Link>
            </Space>
            <Text size="small" italic>
              {record.description}
            </Text>
          </Space>
        )
      },
      {
        title: 'Status',
        key: 'status',
        dataIndex: 'status',
        render: (value: string) => status[value]?.value || ''
      }
    ],
    [status]
  )

  return (
    <div id="review-periods" className="review-periods">
      <div className="review-periods__body">
        <div className="review-periods__action-bar">
          <Space>
            {canModify && (
              <Button key="add" onClick={handleAddReviewPeriod}>
                Add review period
              </Button>
            )}
          </Space>
        </div>
        <Card table>
          <Table rowKey="id" dataSource={reviewPeriods} pagination={false} columns={columns} loading={loading} />
        </Card>
      </div>
      {canModify && <AddReviewPeriodDrawer {...drawerState} onClose={handleCloseDrawer} />}
    </div>
  )
}
