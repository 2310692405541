import React, { FC, useCallback, useMemo } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { Tab, TabTitle, Tabs } from '~/core-components'
import { DocumentTitle } from '~/components'
import { usePermissionGate } from '~/features/iam'
import { PFM_ROUTES } from '~/routes/routes'
import { dispatch } from '~/stores/store'
import { Permission } from '~/constants'
import { refetchReviewPeriods } from '../../reducers'
import { ReviewPeriods } from '../ReviewPeriods/ReviewPeriods'
import './PerformanceOverview.less'

export interface PerformanceOverviewProps {}

export const PerformanceOverview: FC<PerformanceOverviewProps> = () => {
  const match = useRouteMatch<{ tab: string }>()
  const history = useHistory()
  const canViewReviewPeriod = usePermissionGate(Permission.reviewPeriod)

  const handleTabChange = useCallback(
    (activeKey: string) => {
      history.push(`${PFM_ROUTES.tab.replace(':tab?', activeKey)}`)

      if (activeKey === 'period') {
        dispatch(refetchReviewPeriods())
      }
    },
    [history]
  )

  const tabItems = useMemo(() => {
    const result: Tab[] = []
    if (canViewReviewPeriod) {
      result.push({ key: 'period', label: 'Period', children: <ReviewPeriods /> })
    }
    return result
  }, [canViewReviewPeriod])

  return (
    <div className="performance-overview">
      <DocumentTitle title="Performance Review" />
      <div className="performance-overview__body">
        <Tabs
          defaultActiveKey={match.params.tab || 'period'}
          onChange={handleTabChange}
          tabBarExtraContent={{
            left: <TabTitle title="Performance review" />
          }}
          items={tabItems}
        />
      </div>
    </div>
  )
}
