import { request, getBaseUrl } from '~/utils/request'
import { UploadFile } from '~/core-components'
import { EmSelectionViewState } from '~/features/employee'
import { Pagination, Search } from '~/types/common'
import {
  IAddReviewPeriodEmployee,
  ISaveReviewPeriod,
  ReviewPeriodEmployeeState,
  ReviewPeriodFileState,
  ReviewPeriodState
} from '../types'

const baseUrl = getBaseUrl('/performance/reviewperiod')

export const apiGetReviewPeriods = () => request<ReviewPeriodState[]>('get', baseUrl)

export const apiGetReviewPeriod = (id: string) => request<ReviewPeriodState>('get', `${baseUrl}/${id}`)

export const apiSaveReviewPeriod = (reviewPeriod: ISaveReviewPeriod) => {
  let formData = new FormData()

  Object.entries(reviewPeriod).forEach(([key, value]) => {
    if (key === 'files') {
      value.forEach((x: UploadFile) => {
        if (x instanceof File) formData.append(key, x, x.name)
      })
    } else if (key === 'deletedFileIds' && value != null && value !== '') {
      value.forEach((x: string) => {
        formData.append(key, x)
      })
    } else {
      formData.append(key, value === null ? '' : value)
    }
  })

  return request<{ id: string }>('post', baseUrl, formData)
}

export const apiDeleteReviewPeriod = (id: string) => request<void>('delete', `${baseUrl}/${id}`)

export const apiPublishReviewPeriod = (id: string) => request<boolean>('post', `${baseUrl}/${id}/publish`)

export const apiUnpublishReviewPeriod = (id: string) => request<boolean>('post', `${baseUrl}/${id}/unpublish`)

export const apiGetReviewPeriodFiles = (reviewPeriodId: string) =>
  request<ReviewPeriodFileState[]>('get', `${baseUrl}/${reviewPeriodId}/file`)

export const apiGetReviewPeriodEmployees = (reviewPeriodId: string) =>
  request<ReviewPeriodEmployeeState[]>('get', `${baseUrl}/${reviewPeriodId}/employee`)

export const apiGetReviewPeriodEmSelections = (reviewPeriodId: string, viewId: string, params: Pagination & Search) =>
  request<EmSelectionViewState>('get', `${baseUrl}/${reviewPeriodId}/emselections/${viewId}`, params)

export const apiAddReviewPeriodEmployees = (reviewPeriodId: string, data: IAddReviewPeriodEmployee) =>
  request<void>('post', `${baseUrl}/${reviewPeriodId}/employee`, data)

export const apiDeleteReviewPeriodEmployee = (reviewPeriodId: string, employeeId: string) =>
  request<boolean>('delete', `${baseUrl}/${reviewPeriodId}/employee/${employeeId}`)
