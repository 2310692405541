export enum AnswerType {
  RatingScale = 'rating_scale',
  Text = 'text'
}

export const ReviewPeriodFileTypeAllowed = ['.bmp', '.pdf', '.jpg', '.jpeg', '.png']

export enum ReviewPeriodStatus {
  Draft = 'd',
  Published = 'p',
  Completed = 'c'
}

export enum ReviewStatus {
  NotStarted = 'x',
  InProgress = 'p',
  Submitted = 's'
}
