import { createSelector } from '@reduxjs/toolkit'
import { StoreState } from '~/types/store'
import { ReviewPeriodState } from '../types'

export const selectReviewPeriods = createSelector(
  (state: StoreState) => state.performance.reviewPeriods,
  (reviewPeriodsState): ReviewPeriodState[] => {
    const reviewPeriods = Object.values(reviewPeriodsState?.entities || {}) as ReviewPeriodState[]
    return reviewPeriods.sort((a, b) => a!.name.localeCompare(b!.name))
  }
)
