import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { fetchReviewPeriodFiles } from '../actions'
import { selectReviewPeriodFiles } from '../selectors'
import { ReviewPeriodFileState } from '../types'

export const useReviewPeriodFiles = (reviewPeriodId?: string): [ReviewPeriodFileState[], boolean] => {
  const reviewPeriodFiles = useSelector(selectReviewPeriodFiles)(reviewPeriodId) as ReviewPeriodFileState[]
  const loading = useSelector((state: StoreState) => state.performance.reviewPeriodFilesLoading[reviewPeriodId || ''])

  useEffect(() => {
    if (reviewPeriodId) {
      dispatch(fetchReviewPeriodFiles(reviewPeriodId))
    }
  }, [reviewPeriodId])

  return [reviewPeriodFiles, loading]
}
