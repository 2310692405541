import React, { FC } from 'react'
import {
  default as AntUpload,
  UploadProps as AntUploadProps,
  UploadChangeParam as AntUploadChangeParam,
  RcFile as AntRcFile
} from 'antd/lib/upload'
import { default as AntDragger, DraggerProps } from 'antd/lib/upload/Dragger'
import 'antd/es/upload/style'
import { UploadFile as AntUploadFile } from 'antd/lib/upload/interface'
import type { UploadRequestOption as AntRcCustomRequestOptions } from 'rc-upload/lib/interface'

export interface RcFile extends AntRcFile {}
export interface UploadChangeParam extends AntUploadChangeParam {}
export interface UploadFile extends AntUploadFile {}
export interface UploadProps extends AntUploadProps {}
export interface RcCustomRequestOptions extends AntRcCustomRequestOptions {}
export const Upload: FC<UploadProps> = (props: UploadProps) => <AntUpload {...props} />
export const Dragger: FC<DraggerProps> = (props: DraggerProps) => <AntDragger {...props} />
