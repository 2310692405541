import React, { FC, useCallback, useMemo, useState } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import confirm from 'antd/lib/modal/confirm'
import { Button, PageHeader, SecondaryText, Space, Tag, TagType } from '~/core-components'
import { DocumentTitle } from '~/components'
import { useSysOptions } from '~/features/employee'
import { usePermissionGate } from '~/features/iam'
import { PFM_ROUTES } from '~/routes/routes'
import { dispatch } from '~/stores/store'
import { formatDateRange } from '~/utils'
import { Permission, PermissionAction, ReviewPeriodStatus } from '~/constants'
import { useReviewPeriod } from '../../hooks'
import { deleteReviewPeriod, unpublishReviewPeriod } from '../../actions'
import { ReviewPeriodInfoCard } from './components/ReviewPeriodInfoCard'
import { ReviewPeriodEmployeesCard } from './components/ReviewPeriodEmployeesCard'
import { AddReviewPeriodDrawer } from './components/AddReviewPeriodDrawer'
import './ReviewPeriod.less'

interface ReviewPeriodProps {}

interface ReviewPeriodParams {
  id: string
}

interface DrawerState {
  visible: boolean
}

const DEFAULT_DRAWER_STATE: DrawerState = { visible: false }

const routes = [
  {
    path: PFM_ROUTES.tab.replace(':tab?', 'period'),
    breadcrumbName: 'Overview'
  },
  {
    path: '',
    breadcrumbName: 'Review period'
  }
]

export const ReviewPeriod: FC<ReviewPeriodProps> = () => {
  const history = useHistory()
  const match = useRouteMatch<ReviewPeriodParams>()
  const id = match.params.id
  const [reviewPeriod] = useReviewPeriod(id)
  const [editing, setEditing] = useState(false)
  const canModify = usePermissionGate(Permission.reviewTemplate, PermissionAction.Modify)
  const [drawerState, setDrawerState] = useState<DrawerState>(DEFAULT_DRAWER_STATE)
  const [status] = useSysOptions('pfm_review_period_status')

  const handleCloseDrawer = useCallback(() => {
    setDrawerState(DEFAULT_DRAWER_STATE)
  }, [])

  const handleEdit = useCallback(() => setEditing(true), [])
  const handleView = useCallback(() => setEditing(false), [])

  const handleDelete = useCallback(() => {
    if (id && reviewPeriod) {
      confirm({
        title: 'Delete review period',
        content: `Do you want to delete review period "${reviewPeriod?.name}"?`,
        onOk: () => {
          dispatch(deleteReviewPeriod(id))
          history.push(PFM_ROUTES.tab.replace(':tab?', 'period'))
        },
        okText: 'Delete',
        okType: 'danger'
      })
    }
  }, [id, reviewPeriod, history])

  const handleUnpublish = useCallback(() => {
    if (id && reviewPeriod) {
      confirm({
        title: 'Unpublish review period',
        content: `Do you want to revert "${reviewPeriod?.name}" to draft?`,
        onOk: () => {
          dispatch(unpublishReviewPeriod(id))
        },
        okText: 'Unpublish'
      })
    }
  }, [id, reviewPeriod])

  const statusTagType = useMemo((): TagType => {
    if (reviewPeriod?.status === ReviewPeriodStatus.Completed) {
      return 'success'
    } else if (reviewPeriod?.status === ReviewPeriodStatus.Published) {
      return 'secondary'
    } else {
      return 'white'
    }
  }, [reviewPeriod?.status])

  return (
    <div className="review-period">
      <DocumentTitle title="Review Period" />
      <PageHeader
        title={
          <div className="review-period__header">
            <Space>
              <div>{reviewPeriod?.name || 'Review period'}</div>
              {Object.keys(status).includes(reviewPeriod?.status || '') && (
                <Tag type={statusTagType}>{status[reviewPeriod?.status || '']?.value || ''}</Tag>
              )}
            </Space>
            <SecondaryText>{formatDateRange(reviewPeriod?.startDate, reviewPeriod?.endDate)}</SecondaryText>
          </div>
        }
        breadcrumb={{ routes }}
        extra={
          canModify && editing ? (
            <Button key="add" onClick={handleDelete}>
              Delete
            </Button>
          ) : (
            reviewPeriod?.status === ReviewPeriodStatus.Published && (
              <Button key="revert" onClick={handleUnpublish}>
                Unpublish
              </Button>
            )
          )
        }
      />
      <div className="review-period__body">
        <ReviewPeriodInfoCard
          reviewPeriod={reviewPeriod}
          onEdit={handleEdit}
          onSave={handleView}
          onCancel={handleView}
        />
        <ReviewPeriodEmployeesCard reviewPeriod={reviewPeriod} />
        {canModify && <AddReviewPeriodDrawer {...drawerState} onClose={handleCloseDrawer} />}
      </div>
    </div>
  )
}
