import React, { FC, useState, useCallback, useEffect, ChangeEvent } from 'react'
import { useHistory } from 'react-router-dom'
import { Form, Input, Space, Tag } from '~/core-components'
import { Col, DrawerForm, LveKeyValues, MonthKeyValues, Row, SalaryInput, SysOptions } from '~/components'
import { dispatch } from '~/stores/store'
import { useFocus } from '~/hooks/use-focus'
import { ActionResult, Errors } from '~/types/store'
import { SETTINGS_ROUTES } from '~/routes/routes'
import { addLeaveType } from '../../../actions'
import { IAddLeaveType } from '../../../types'
import { LvePeriodBasis, LveSysLeaveType, LveUnit, LveVisibleType } from '~/constants'
import { KeyValue } from '~/types/common'
import { useSysLtFields } from '~/features/leave/hooks'

export interface AddLeaveTypeDrawerProps {
  visible: boolean
  onClose: () => void
}

const EMPTY_FORM_DATA: IAddLeaveType = {
  name: '',
  sysLeaveTypeCode: '',
  periodBasis: LvePeriodBasis.calendar,
  periodStart: 1,
  periodDuration: 12,
  unit: LveUnit.days
}

export const AddLeaveTypeDrawer: FC<AddLeaveTypeDrawerProps> = ({ visible, onClose }: AddLeaveTypeDrawerProps) => {
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState<IAddLeaveType>(EMPTY_FORM_DATA)
  const [focusRef, setFocus] = useFocus(true)
  const [errors, setErrors] = useState<Errors>()
  const [sysLtFields, sysLtFieldsLoading] = useSysLtFields(formData.sysLeaveTypeCode)

  useEffect(() => {
    setTimeout(() => visible && setFocus(), 100)
    setErrors(undefined)
  }, [visible, setFocus])

  useEffect(() => {
    setFormData(EMPTY_FORM_DATA)
  }, [])

  const handleFormDataChange = useCallback((updates: { [field: string]: any }) => {
    setFormData(formData => ({ ...formData, ...updates }))
  }, [])

  const handleOk = useCallback(async () => {
    let result: ActionResult | undefined
    setLoading(true)
    try {
      result = await dispatch(addLeaveType(formData))
    } finally {
      setLoading(false)
    }
    if (result?.errors) {
      setErrors(result.errors)
    }
    if (!result?.errors) {
      typeof onClose === 'function' && onClose()
      setFormData(EMPTY_FORM_DATA)

      if (result?.result?.id) {
        const leaveTypeId = result.result.id
        history.push(`${SETTINGS_ROUTES.leaveType.replace(':id', leaveTypeId)}`)
      }
    }
  }, [formData, onClose, history])

  const isVisible = useCallback(
    (fieldName: string) => {
      return sysLtFields[fieldName]?.visibleType === LveVisibleType.basic
    },
    [sysLtFields]
  )

  return (
    <DrawerForm
      open={visible}
      title="Add leave type"
      onClose={onClose}
      confirmLoading={loading}
      width={500}
      formId="form-leave-type"
    >
      <Form id="form-leave-type" onFinish={handleOk}>
        <Row>
          <Col span={24}>
            <Form.Item label="Name" validateStatus={errors?.name ? 'error' : ''} help={errors?.name}>
              <Input
                ref={focusRef}
                value={formData.name}
                onChange={(event: ChangeEvent<HTMLInputElement>) => handleFormDataChange({ name: event.target.value })}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              label="Category"
              validateStatus={errors?.sysLeaveTypeCode ? 'error' : ''}
              help={errors?.sysLeaveTypeCode}
            >
              <LveKeyValues
                id="sysLeaveType"
                display="keyvalue"
                renderItem={kv => (
                  <>
                    <Tag>{kv.key}</Tag> {kv.value}
                  </>
                )}
                value={formData.sysLeaveTypeCode}
                onChange={(sysLeaveTypeCode?: string) => handleFormDataChange({ sysLeaveTypeCode })}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={30}>
          {isVisible('period_basis') && (
            <Col span={12}>
              <Form.Item
                label="Period basis"
                validateStatus={errors?.periodBasis ? 'error' : ''}
                help={errors?.periodBasis}
                tooltip={{
                  title: 'Period basis cannot be changed once leave type is created',
                  icon: (
                    <span>
                      <i className="fal fa-circle-info" />
                    </span>
                  )
                }}
              >
                <SysOptions
                  loading={sysLtFieldsLoading}
                  type="lve_period_basis"
                  value={formData.periodBasis}
                  onFilter={(value: KeyValue | undefined) =>
                    [LvePeriodBasis.calendar, LvePeriodBasis.custom].includes(value?.key || '')
                  }
                  onChange={(value: string) => handleFormDataChange({ periodBasis: value })}
                />
              </Form.Item>
            </Col>
          )}
        </Row>
        <Row hidden={formData.periodBasis !== LvePeriodBasis.custom} gutter={30}>
          {isVisible('period_start') && (
            <Col>
              <Form.Item
                label="Period start"
                validateStatus={errors?.periodStart ? 'error' : ''}
                help={errors?.periodStart}
              >
                <MonthKeyValues
                  allowClear={false}
                  value={formData.periodStart}
                  onChange={(value: string) => handleFormDataChange({ periodStart: value })}
                />
              </Form.Item>
            </Col>
          )}
          {isVisible('period_duration') && (
            <Col flex={1}>
              <Form.Item
                label="Duration"
                validateStatus={errors?.periodDuration ? 'error' : ''}
                help={errors?.periodDuration}
              >
                <Space>
                  <SalaryInput
                    min={1}
                    precision={0}
                    style={{ width: 80 }}
                    value={formData.periodDuration}
                    onChange={(periodDuration: number | null) => handleFormDataChange({ periodDuration })}
                  />
                  <span>months</span>
                </Space>
              </Form.Item>
            </Col>
          )}
        </Row>
        {formData.sysLeaveTypeCode === LveSysLeaveType.grl && (
          <Row>
            <Col span={24}>
              <Form.Item label="Unit">
                <SysOptions
                  type="lve_unit"
                  allowClear={false}
                  value={formData.unit}
                  onChange={(unit: string) => handleFormDataChange({ unit })}
                  onFilter={(value: KeyValue | undefined) => LveUnit.weeks !== value?.key}
                />
              </Form.Item>
            </Col>
          </Row>
        )}
      </Form>
    </DrawerForm>
  )
}
