import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'
import { ReviewPeriodEmployeeState } from '../types'

export const selectReviewPeriodEmployees = createSelector(
  (state: StoreState) => state.performance.reviewPeriodEmployees,
  reviewPeriodEmployeesState =>
    memoize((reviewPeriodId?: string) => {
      return Object.values(
        reviewPeriodEmployeesState[reviewPeriodId || '']?.entities || {}
      ) as ReviewPeriodEmployeeState[]
    })
)
