import { showSuccess, showError } from '~/utils'
import { ActionResult, ThunkResult } from '~/types/store'
import { IAddReviewPeriodEmployee } from '../types'
import { apiAddReviewPeriodEmployees } from '../api/review-period.api'
import { fetchReviewPeriodEmployees } from './fetch-review-period-employees'

export const addReviewPeriodEmployees = (reviewPeriodId: string, data: IAddReviewPeriodEmployee): ThunkResult<void> => {
  return async (dispatch): Promise<ActionResult> => {
    const { status, errors, message, errorData } = await apiAddReviewPeriodEmployees(reviewPeriodId, data)
    if (status) {
      dispatch(fetchReviewPeriodEmployees(reviewPeriodId))
      showSuccess('Added')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
