import { replaceQuickBooksClaimItemMaps, setQuickBooksClaimItemMapsLoading } from '../reducers'
import { showSuccess, showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { apiUpdateQuickBooksClaimItemMaps } from '../api/quick-books.api'
import { QuickBooksClaimItemMapState } from '../types'

export const updateQuickBooksClaimItemMaps = (
  realmId: string,
  data: QuickBooksClaimItemMapState[]
): ThunkResult<void> => {
  return async dispatch => {
    if (!realmId) return

    try {
      dispatch(setQuickBooksClaimItemMapsLoading({ realmId, loading: true }))
      const { status, result, errors, message, errorData } = await apiUpdateQuickBooksClaimItemMaps(realmId, data)

      if (status) {
        dispatch(replaceQuickBooksClaimItemMaps({ realmId, data: result }))
        showSuccess('Saved')
      } else {
        if (Object.keys(errors).length === 0) {
          showError(message, errorData)
        }
      }
      return { errors }
    } finally {
      dispatch(setQuickBooksClaimItemMapsLoading({ realmId, loading: false }))
    }
  }
}
